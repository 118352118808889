import { Map } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Editor } from 'ole';
import { CAD, Difference, Draw, Intersection, Modify, Rotate, Union, } from 'ole/build/control';
import * as React from 'react';
import { MapLayerResponse, ViewsService, WorkflowDetailsResponse } from '../../services/openapi';
import { Loading } from '../Loading';

import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, makeStyles, shorthands } from '@fluentui/react-components';
import { Extent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';
import BufferControl from 'ole/build/control/buffer';
import 'ole/style/ole.css';
import CrossIcon from "../../assets/icons/cross.svg";
import RoundedPlusIcon from "../../assets/icons/plus_circle.svg";
import OpenLayers from '../OpenLayers';
import * as TGJ from '@mapbox/togeojson';

interface Props {
    flightId?: string;
    config?: any;
    onSave?: (geoJson: any) => Promise<any>;
    onFinish?: () => void;
    onClose: () => void;
    onDownloadSuccess: () => void;
    workflow?: WorkflowDetailsResponse;
    onSaveFileUploader?: (file: File | null) => void;
    isProjectFileUploader?: boolean;
    onNotifyError?: (msg: string) => void;

}

export const GeoJsonEditor = ({ flightId, config, onSave, onFinish, onClose, onDownloadSuccess, workflow, onSaveFileUploader, isProjectFileUploader, onNotifyError }: Props) => {
    const [loading, setLoading] = React.useState(false);
    const classes = useStyles();
    const [flightMapLayers, setFlightMapLayers] = React.useState<MapLayerResponse[]>([]);
    const uploadInputRef = React.useRef<HTMLInputElement>(null);
    const [continueModalVisible, setContinueModalVisible] = React.useState(false);
    const [zoomExtent, setZoomExtent] = React.useState<Extent | null>(null);
    const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
    const [source] = React.useState(new VectorSource({
        wrapX: false,
    }));
    const [editLayer] = React.useState(new VectorLayer({
        zIndex: 99,
        source,
        title: 'Editor'
    } as any));
    const [_editor, setEditor] = React.useState<Editor | null>(null);

    React.useEffect(() => {
        if (!flightId) {
            setLoading(false);
            return;
        }
        ViewsService.getFlightViews(flightId)
            .then((layersRes) => {
                setFlightMapLayers(layersRes.layers);
                setLoading(false);
            })
            .catch((err) => console.error(err));
    }, [flightId, setLoading]);

    const setMap = (map: Map) => {
        if (!map || !map.getTargetElement()) return;

        if (flightMapLayers.length === 0) {
            let view = map.getView();
            let newCenter = fromLonLat([78.9629, 20.5937]);
            if (workflow && workflow.name === "CREATE_BFT_DEM_USGS") {
                newCenter = fromLonLat([-98.5795, 39.8283]);
            }
            view.setCenter(newCenter);
            view.setZoom(5);
        }
        if (_editor) {
            _editor.remove();
            (_editor as any).map = null;
        }
        map.addLayer(editLayer);
        const editor = new Editor(map, {});
        const layerEditConfig = { source } as any;

        editor.addControls([
            new CAD(layerEditConfig),
            // new Draw(config),
            new Draw({ source, type: 'Point' } as any),
            new Draw({ source, type: 'LineString' } as any),
            new Draw({ source, type: 'Polygon' } as any),
            new Draw({ source, type: 'Circle' } as any),
            new Modify(layerEditConfig),
            new Rotate(layerEditConfig),
            new BufferControl(layerEditConfig),
            new Union(layerEditConfig),
            new Intersection(layerEditConfig),
            new Difference(layerEditConfig),
        ]);
        setEditor(editor);
    };

    const includeFile = (f: File) => {
        const fr = new FileReader();
        fr.onload = (ev) => {
            if (fr.result) {
                try {
                    const name = (f.name || '').toLowerCase();
                    if (name.endsWith('.geojson')) {
                        includeJson(`${fr.result}`);
                    } else if (name.endsWith('.kml')) {
                        includeKml(`${fr.result}`)
                    }
                } catch (_e) {
                    console.error(_e);
                }
            }
        };
        fr.readAsText(f);
    };

    const includeJson = (v: any) => {
        const format = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: 'EPSG:3857'
        });
        const fs = format.readFeatures(v);
        source.addFeatures(fs);
        setZoomExtent(source.getExtent())
    };

    const includeKml = (v: any) => {
        const doc = new DOMParser().parseFromString(v, 'text/xml');
        const format = new GeoJSON({
            dataProjection: "EPSG:4326",
            featureProjection: 'EPSG:3857'
        });
        const fs = format.readFeatures(TGJ.kml(doc));
        source.addFeatures(fs);
        setZoomExtent(source.getExtent())
    };

    const download = () => {
        if (!_editor) return;
        const format = new GeoJSON();
        const exportConfig = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
        };
        const str = format.writeFeatures(source.getFeatures(), exportConfig);
        const blob = new Blob([str], { type: "application/json" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        (a as any).style = "display: none";
        (a as any).target = "_blank";
        document.body.appendChild(a);
        a.href = url;
        a.download = 'features.geojson';
        a.click();
        onDownloadSuccess();
        window.URL.revokeObjectURL(url);
        // window.location.assign(url);
    };

    const save = () => {
        if (!_editor) return;
        const format = new GeoJSON();
        const exportConfig = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
        };
        const str = format.writeFeatures(source.getFeatures(), exportConfig);
        if (onSave) {
            onSave(str);
        }
    };

    const finish = () => {
        if (!_editor) return;
        const format = new GeoJSON();
        const exportConfig = {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857',
        };
        const str = format.writeFeatures(source.getFeatures(), exportConfig);
        if (onSave && onFinish) {
            onSave(str).then(() => {
                onFinish();
            });
        }
    };

    const closeContinueModal = () => { setContinueModalVisible(false) }
    const openContinueModal = () => { setContinueModalVisible(true) }

    if (loading) {
        return <Loading />;
    }
    const handleNewSave = () => {
        if (selectedFile) {
            if (onSaveFileUploader) {
                onSaveFileUploader(selectedFile);
            } else {
                console.error("onSaveFileUploader is not defined.");
                onNotifyError?.("onSaveFileUploader is not defined.");
            }
        } else {
            onNotifyError?.("No file selected. Please upload a file first.");
        }
    };


    return <><div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
    }}>
        <div className={classes.headerContainer}>
            <h2 className={classes.header}>{isProjectFileUploader?"Upload File":"Draw Vector Shapes"}</h2>
            <img src={CrossIcon} className={classes.close} onClick={onClose} />
        </div>
        <div style={{
            flexGrow: 1, marginBottom: "1rem", position: "relative"
        }}>
            <OpenLayers layers={flightMapLayers} onLoaded={(m) => setMap(m)} showFullScreenIcon={false} showMeasurements={false} showToolBar={true} showLayers={false} showDownload={true} onClickDownload={download} setFocus={zoomExtent} />
        </div>
        <div className={classes.actionsContainer}>
            <input type='file' style={{ display: "none" }}
                accept='.geojson,.kml'
                onChange={(e) => {
                    const files = e.target.files;
                    if (files?.length) {
                        const name = files[0].name.toLowerCase();
                        if (name.endsWith(".geojson") || name.endsWith(".kml")) {
                            includeFile(files[0]);
                            setSelectedFile(files[0]);
                        }
                    } else {
                        alert(`Please select a valid .geojson or .kml file.`);
                    }
                    e.target.files = null;
                }}
                ref={uploadInputRef}
            />

            <div className={classes.uploadContainer} onClick={() => {
                if (uploadInputRef && uploadInputRef.current) {
                    uploadInputRef.current.click()
                }
            }}>
                <img src={RoundedPlusIcon} alt="upload" />
                <p>Add GeoJSON or KML File</p>
            </div>

            <div className={classes.buttonsContainer}>
                {
                    !isProjectFileUploader ?
                        <div style={{ display: "flex" }}>
                            <Button disabled={!onSave} onClick={() => save()} className={classes.secondoryButton} style={{ marginRight: "1em" }}>Save</Button>
                            <Button disabled={!onSave || !onFinish} onClick={openContinueModal} className={classes.primaryButton}>Continue</Button>
                        </div> :
                        <Button className={classes.secondoryButton} onClick={handleNewSave}>Upload</Button>
                }
            </div>
        </div>
    </div>

        <Dialog
            open={continueModalVisible}
            modalType='non-modal'
        >
            <DialogSurface style={{ width: "650px", padding: "0px" }}>
                <DialogBody style={{ padding: "24px" }}>
                    <DialogTitle action={null} style={{ color: "#586A84" }}>Continue to next stage of this workflow?</DialogTitle>
                    <DialogContent style={{ color: "#586A84" }}>
                        All changes will be submitted and this action cannot be reversed.
                    </DialogContent>
                </DialogBody>


                <div style={{ display: "flex", width: "100%", paddingTop: "24px", alignItems: "center", gap: "1rem", justifyContent: "flex-end", padding: "24px" }}>
                    <button className={classes.secondoryButton} onClick={closeContinueModal}>Cancel</button>
                    <button className={classes.primaryButton} onClick={() => {
                        closeContinueModal();
                        finish();
                    }}>Continue</button>
                </div>
            </DialogSurface>
        </Dialog >
    </>
};

const useStyles = makeStyles({
    headerContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
    },
    header: {
        color: "#3E3E3E",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "36px"
    },
    close: {
        cursor: "pointer"
    },
    actionsContainer: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center"
    },
    buttonsContainer: {
        display: 'flex',
        ...shorthands.gap("16px")
    },
    secondoryButton: {
        display: "flex",
        minWidth: "112px",
        height: "36px",
        padding: "6px 32px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        borderRadius: "6px",
        ...shorthands.borderRadius("6px"),
        ...shorthands.border("0.75px", "solid", "#586A84"),
        cursor: "pointer",
        backgroundColor: "#FFF",
    },
    primaryButton: {
        display: "flex",
        height: "36px",
        minWidth: "112px",
        padding: "6px 32px",
        justifyContent: "center",
        alignItems: "center",
        flexShrink: 0,
        ...shorthands.borderRadius("6px"),
        ...shorthands.border("0.75px", "solid", "#FFF"),
        backgroundColor: "#007AFF",
        color: "#FFF",
        cursor: "pointer",
    },
    uploadContainer: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("4px"),
        color: "#586A84",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        ":hover": {
            color: "black",
        },
    }
})