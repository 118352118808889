import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Field, Input, makeStyles, Menu, MenuItem, MenuList, MenuOpenChangeData, MenuPopover, MenuTrigger, Popover, PopoverSurface, PopoverTrigger, Textarea, tokens } from "@fluentui/react-components";
import Form from "@rjsf/fluentui-rc";
import validator from "@rjsf/validator-ajv8";
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthContext";
import moreInfoIcon from "../../assets/icons/moreInfo.svg";
import { OrganisationResponse, OrganisationsService, PluginResponse, PluginsService, ProjectRequest, ProjectsService, WorkflowTemplateResponse, WorkflowTemplatesService } from '../../services/openapi';
import { WorkflowCard } from "../WorkflowCard";
import { PrimaryButton2 } from "../common/PrimaryButton2";
import { getSchema, NodeSchema, uiSchema } from "../utils/getSchema";

const pluginIDs = new Set([
    "ODM",
    "ALIGN",
    "ALIGN_W_GCPS",
    "METASHAPE_W_GCPS",
    "METASHAPE",
    "PREPARE_DATASET"
]);

export const NewProjectForm = () => {
    const [form, setForm] = React.useState<{
        name: string,
        notes?: string,
        workflowTemplate?: string,
        organisationId?: string,
        config: Record<string, string>,
    }>({ name: '', config: {} });
    const user = useContext(AuthContext);
    const [orgs, setOrgs] = React.useState<OrganisationResponse[] | null>();
    const [templates, setTemplates] = React.useState<WorkflowTemplateResponse[]>([]);
    const [isValidMail, setIsValidMail] = React.useState(false);
    const [error, setError] = React.useState('');
    const navigate = useNavigate();
    const classes = useStyles();
    const { orgId } = useContext(AuthContext)
    const [showOrgMessage, setShowOrgMessage] = React.useState(false);
    const [showOrgNote, setShowOrgNote] = React.useState(false);
    const [schemaList, setSchemaList] = React.useState<NodeSchema[]>([]);
    const { setIsNewProjectOpen } = useContext(AuthContext);
    const [disabled, setDisabled] = useState(false);
    const [plugins, setPlugins] = React.useState<Array<PluginResponse>>();

    React.useEffect(() => {
        WorkflowTemplatesService.getTemplates()
            .then((ts) => {
                const defaultTemplates = ts.templates.filter(template => {
                    return template.json.nodes.some((node: any) => pluginIDs.has(node.plugin_id));
                })
                setTemplates(defaultTemplates);
            })
            .catch((err) => setError(`Could not get workflow templates: ${err}`));
        OrganisationsService.getMyOrganisations()
            .then((os) => {
                setShowOrgMessage(os.organisations.length === 0);
                setOrgs(os.organisations);
                if (os.organisations.length === 1) {
                    setForm({ ...form, organisationId: os.organisations[0].id });
                }
            })
            .catch((err) => setError(`Could not get your organisations: ${err}`));

        PluginsService.getPlugins()
            .then(plugins => setPlugins(plugins.plugins))
            .catch(err => console.error("ERROR GETTING PLUGINS : ", err));
    }, []);

    useEffect(() => {
        if (!user.me?.admin) {
            OrganisationsService.getMyRoles().then(data => {
                if (data.roles.length === 0) {
                    setDisabled(true)
                }
                const role = data.roles.find(item => item.organisationId === form.organisationId);
                if (!role || role?.role === "viewer") {
                    setDisabled(true);
                } else {
                    setDisabled(false);
                }
            })
        }
    }, [form.organisationId]);

    const create = () => {
        const request: ProjectRequest = {
            name: form.name,
            notes: form.notes,
            workflowTemplate: form.workflowTemplate,
            organisationId: form.organisationId || '',
            config: form.config,
        };
        ProjectsService.createProject(request)
            .then((p) => {
                navigate(`/projects/${p.id}`)
                setIsNewProjectOpen(false)
            })
            .catch((err) => {
                console.log("Err", err.body)
                let errorString = "Error while creating project"
                if (err.body) {
                    errorString += `: ${err.body}`
                }
                setIsValidMail(true)
            });
    };

    const getTemplateName = () => {
        const t = (templates || []).find((t) => t.id === form?.workflowTemplate);
        return t?.name || '';
    };

    const getOrgName = () => {
        const org = (orgs || []).find((o) => o.id === form?.organisationId);
        return org?.name || '';
    }

    const checkDisabled = () => {
        if (disabled) { return true };
        return (!form.name || !form?.organisationId)
    }

    return <>
        {showOrgMessage ? <div className={classes.orgMessageContainer}>You have not been added to any organisation, Please contact your administrator</div> :
            <div>
                <div style={{ marginTop: '2em' }}>
                    <div className={classes.inputName}>
                        Organisation *
                    </div>
                    {
                        (showOrgNote && (orgs?.length ?? 0) >= 2) && <p className={classes.orgNote}>To create a project in a different organisation, switch to the desired organisation first.</p>
                    }
                    <Menu positioning={'below-start'} onOpenChange={(e: any, data: MenuOpenChangeData) => setShowOrgNote(data.open)}>
                        <MenuTrigger>
                            <div>
                                <Button size='large' style={{ width: "100%", background: "#ECF3FF", border: "none", paddingTop: "1.3em", paddingBottom: "1.3em", borderRadius: "4px", height: "2rem", justifyContent: "flex-start", fontWeight: "normal", fontSize: "14px" }}>
                                    {getOrgName() || "-- Select One --"}
                                </Button>
                            </div>
                        </MenuTrigger>
                        <MenuPopover style={{ width: "550px", maxWidth: "550px" }} className={classes.dropdownPopover}>
                            <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                {(orgs || []).map((o) => {
                                    return <MenuItem
                                        key={o.id}
                                        style={{
                                            backgroundColor: tokens.colorNeutralBackground1,
                                            minWidth: "100%",
                                            opacity: orgId?.orgId === o.id ? "1" : "0.5",
                                            cursor: orgId?.orgId === o.id ? "pointer" : "not-allowed",
                                            pointerEvents: orgId?.orgId === o.id ? "auto" : "none",
                                        }}
                                        onClick={() => {
                                            setForm({ ...form, organisationId: o.id });
                                        }}
                                    >
                                        <p>{o.name}</p>
                                    </MenuItem>
                                })}
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <div className={classes.inputName}>
                        Project Name *
                    </div>
                    <div>
                        <Field validationMessageIcon={null} validationMessage={isValidMail ? "This project name already exists. Please choose a different name." : null}>
                            <Input
                                style={{
                                    width: '100%',
                                    border: isValidMail ? "1px solid red" : "none"

                                }}
                                className={classes.customInput}
                                value={form.name}
                                onChange={(e) => {
                                    setIsValidMail(false)
                                    setForm({ ...form, name: e.target.value });
                                }} />
                        </Field>
                    </div>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <div className={classes.inputName}>
                        Project Notes
                    </div>
                    <div>
                        <Textarea className={classes.customInput}
                            style={{ width: '100%' }}
                            value={form.notes || ''}
                            onChange={(e) => {
                                setForm({ ...form, notes: e.target.value });
                            }}
                        />
                    </div>
                </div>
                <div style={{ marginTop: '2em' }}>
                    <div className={classes.inputName} style={{ display: "flex", alignItems: "center" }}>
                        Default Workflow
                        <Popover withArrow>
                            <PopoverTrigger disableButtonEnhancement>
                                <img style={{ marginLeft: ".5em", cursor: "pointer" }} src={moreInfoIcon} />

                            </PopoverTrigger>

                            <PopoverSurface className={classes.popoverSurface} tabIndex={-1} style={{ width: "20%" }}>
                                <p>Default workflows are pre-built templates designed by industry experts, offering a quick start for common data analysis tasks.</p>
                            </PopoverSurface>
                        </Popover>
                    </div>
                    <Menu positioning={'below-start'}>
                        <MenuTrigger     >
                            <div>
                                <Button size='large' style={{ width: "100%", height: "2rem", background: "#ECF3FF", border: "none", paddingTop: "1.3em", paddingBottom: "1.3em", borderRadius: "4px", justifyContent: "flex-start", fontWeight: "normal", fontSize: "14px" }}>
                                    {getTemplateName() || "-- Select One --"}
                                </Button>
                            </div>
                        </MenuTrigger>
                        <MenuPopover style={{ width: "550px", maxWidth: "550px" }} className={classes.dropdownPopover}>
                            <MenuList style={{ overflowY: 'auto', maxHeight: '20em' }}>
                                {templates.map((t) => {
                                    return <MenuItem
                                        key={t.id}
                                        style={{
                                            backgroundColor: tokens.colorNeutralBackground1,
                                            minWidth: "100%"
                                        }}
                                        onClick={() => {
                                            setForm({ ...form, workflowTemplate: t.id });
                                            getSchema(t, setSchemaList, plugins)
                                        }}
                                    >
                                        <WorkflowCard template={t} />
                                    </MenuItem>
                                })}
                            </MenuList>
                        </MenuPopover>
                    </Menu>

                    {(schemaList && schemaList.length > 0) && <Accordion collapsible>
                        <AccordionItem value="1">
                            <AccordionHeader>Config</AccordionHeader>
                            <AccordionPanel >
                                <Accordion collapsible>
                                    {schemaList.map((nodeSchema, idx) =>
                                        <AccordionItem value={idx}>
                                            <AccordionHeader>{nodeSchema.nodeId}</AccordionHeader>
                                            <AccordionPanel >
                                                <Form
                                                    formData={form.config[nodeSchema.nodeId] || {}}
                                                    schema={nodeSchema.schema}
                                                    validator={validator}
                                                    onChange={(e) => {
                                                        const nodeForm = {} as any;
                                                        if (e.formData) {
                                                            nodeForm[nodeSchema.nodeId] = e.formData;
                                                        }
                                                        setForm(prev => ({
                                                            ...prev,
                                                            config: {
                                                                ...prev.config,
                                                                ...nodeForm
                                                            }
                                                        }))
                                                    }}
                                                    uiSchema={uiSchema}
                                                />
                                            </AccordionPanel>
                                        </AccordionItem>
                                    )}
                                </Accordion>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>}

                </div>
                <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'end', gap: "1rem" }}>
                    {error && <div style={{ color: "red", marginRight: "1rem" }}>{error}</div>}
                    <Button appearance="secondary" onClick={() => {
                        setIsNewProjectOpen(false)
                    }}>
                        Cancel
                    </Button>
                    <PrimaryButton2
                        disabled={checkDisabled()}
                        label="Create"
                        onClick={create}
                    />
                </div>
            </div >}
    </>
};

const useStyles = makeStyles({
    dropdownPopover: {
        width: "100% !important",
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "500"
    },
    orgMessageContainer: {
        display: "flex",
        alignItems: "center",
        height: "50%",
        justifyContent: "center",
        textAlign: "center",
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600,
    },
    inputName: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "500",
        fontSize: "1.1rem"
    },
    customInput: {
        padding: ".6em",
        border: "none",
        background: "#ECF3FF",
        borderRadius: "4px",
    },
    popoverSurface: {
        transform: "translate3d(0,0, 0, 0) !important",
        boxShadow: " -1px 1px 10px 0px #00000040",
    },
    toaster: {
        width: "600px",
    },
    orgNote: {
        fontSize: ".9rem",
        margin: ".5em 0",
        display: "flex",
        alignItems: "center"
    }
});

