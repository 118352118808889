import React from 'react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Label } from '@fluentui/react-components';

interface ModalProps {
    visible: boolean;
    toggleModal: (flag: boolean) => void;
}


function InsufficientCreditModal(props: ModalProps) {
    const { visible, toggleModal } = props;

    return <Dialog
        open={visible}
    >
        <DialogSurface style={{ boxShadow: "-1px 1px 10px 0px #00000026", borderRadius: "8px" }}>
            <DialogBody>
                <DialogTitle style={{ color: "#586A84" }}>Low Credit Balance!</DialogTitle>
                <DialogContent style={{ margin: "1em 0" }}>
                    <Label htmlFor={"name-input"} style={{ fontSize: "1rem", color: "#586A84" }}>
                        You don't have enough credits to run this workflow. Please contact our support team at <span style={{ color: "#007AFF" }}>support@flyghtcloud.com</span> for adding more credits or any other assistance.
                    </Label>
                </DialogContent>
                <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" style={{ background: "#007AFF", padding: '.5em', borderRadius: "8px" }} onClick={() => toggleModal(false)}>Okay</Button>
                    </DialogTrigger>
                </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog >
}

export default InsufficientCreditModal