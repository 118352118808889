import {
  PluginResponse,
  WorkflowTemplateResponse,
} from '../../services/openapi';

export interface NodeSchema {
  nodeId: string;
  schema: any;
}

export const getSchema = (
  t: WorkflowTemplateResponse,
  callback: (schema: NodeSchema[]) => void,
  plugins: PluginResponse[] | undefined
) => {
  if (!t || !t.json['nodes']) {
    return;
  }
  const nodesPlugins: string[] = t.json['nodes'].map(
    (node: any) => node['plugin_id'] as string
  );
  if (!plugins || !nodesPlugins || !nodesPlugins.length) {
    return;
  }
  let schemas: NodeSchema[] = [];
  for (const node of t.json['nodes'] || []) {
    const pluginId = node['plugin_id'] as string;
    if (!pluginId) continue;
    const plugin = plugins.find((plugin) => plugin.id === pluginId);
    if (!plugin) continue;
    if (plugin.id.endsWith('_UPLOADER') || plugin.id.endsWith('_PICKER')) {
      continue;
    }

    let schema = plugin.configSchema;
    if (
      [
        'METASHAPE',
        'METASHAPE_W_GCPS',
        'ALIGN',
        'ALIGN_W_GCPS',
        'ODM',
        'MERGE',
        'MERGE_3D_MODEL',
        'METASHAPE_3D_MODEL',
        'METASHAPE_3D_MODEL_W_GCPS',
      ].indexOf(pluginId) > -1
    ) {
      schema = {
        type: 'object',
        properties: {
          quality: {
            type: 'string',
            description: 'Processing quality',
            default: 'medium',
            oneOf: [
              {
                const: 'low',
                title: 'Low',
              },
              {
                const: 'medium',
                title: 'Medium',
              },
              {
                const: 'high',
                title: 'High',
              },
            ],
          },
        },
        required: ['quality'],
      };
    }
    if (!schema.hasOwnProperty('properties')) continue;
    if (Object.keys(schema.properties).length === 0) continue;
    schemas.push({ nodeId: node['id'] as string, schema });
  }
  callback(schemas);
};

export const uiSchema = {
  'ui:submitButtonOptions': {
    norender: true,
  },
  file_type: {
    'ui:description': ' ',
  },
  quality: {
    'ui:widget': 'select',
    'ui:placeholder': 'medium',
  },
};
