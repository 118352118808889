import * as React from "react";
import { PluginResponse, QueueItemResponse, TaskFileResponse, TaskFilesResponse, TaskInfoResponse } from "../../services/openapi";
import { API_BASE } from "../../constants";
import { Loading } from "../Loading";

interface IProps {
    token: string;
    plugin: PluginResponse;
    task: QueueItemResponse;
    onDone: () => void;
    onFail: () => void;
    onCancel: () => void;
}

export const PluginTaskDetails = ({ token, plugin, task, onDone, onFail, onCancel }: IProps) => {
    const [details, setDetails] = React.useState<TaskInfoResponse | undefined>();
    const [files, setFiles] = React.useState<TaskFilesResponse | undefined>();

    React.useEffect(() => {
        Promise.all([
            fetch(`${API_BASE}/api/tasks/${task.parent_task_id}/info`, {
                headers: { Authorization: `Bearer ${token.trim()}` }
            }),
            fetch(`${API_BASE}/api/tasks/${task.parent_task_id}/files`, {
                headers: { Authorization: `Bearer ${token.trim()}` }
            }),
        ]).then(([info, files]) => {
            return Promise.all([info.json(), files.json()]);
        }).then(([info, files]) => {
            setDetails(info);
            setFiles(files);
        }).catch(err => console.log(err));
    }, [token, plugin.id, task.id]);

    const syncFile = (file: TaskFileResponse) => {
        fetch(`${API_BASE}/api/tasks/${task.parent_task_id || ''}/files/${file.id}/sync`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            } else {
                throw res;
            }
        }).then((file: TaskFileResponse) => {
            // update the output file
            const fs = [];
            for (const f of files?.outputs || []) {
                if (f.id === file.id) {
                    fs.push(file);
                } else {
                    fs.push(f);
                }
            }
            setFiles({ inputs: files?.inputs || [], outputs: fs });
        })
            .catch(err => console.error(err));
    }

    if (!details || !files) {
        return <Loading />;
    }

    const canFinish = !files.outputs.find(f => f.status !== 'uploaded');

    return <div>
        <div>Project: {details.project.name}</div>
        <div>Flight: {details.flight?.name}</div>
        {/* TODO: change info API to get config */}
        <div>Config: {JSON.stringify(task?.payload?.Execute?.config)}</div>
        <div style={{ border: '1px solid silver', padding: '1em' }}>
            Input Files:
            {files.inputs.map(f => {
                return <InputFile key={f.id} f={f} t={task} token={token} />
            })}
        </div>
        <div style={{ border: '1px solid silver', padding: '1em' }}>
            Output Files:
            {files.outputs.map(f => {
                return <OutputFile
                    key={f.id}
                    f={f}
                    t={task}
                    token={token}
                    onUploaded={() => syncFile(f)}
                />
            })}
        </div>
        <div
            style={{
                display: 'flex'
            }}
        >
            <button
                disabled={!canFinish}
                onClick={() => onDone()}
            >Finish Task</button>
            <div style={{ flexGrow: 1 }}></div>
            <button
                onClick={() => onFail()}
            >Reject Task</button>
            <button
                style={{

                }}
                onClick={() => onCancel()}
            >Close</button>
        </div>
    </div>;
}

interface IInputFileProps {
    f: TaskFileResponse;
    t: QueueItemResponse;
    token: string;
}

const InputFile = ({ f, t, token }: IInputFileProps) => {
    const download = () => {
        fetch(`${API_BASE}/api/tasks/${t.parent_task_id}/files/${f.id}/presigned_url`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${token.trim()}` }
        }).then(res => res.text())
            .then(url => {
                console.log(url);
                window.open(url, "_blank");
            })
            .catch(err => console.error(err));
    }
    return <div style={{ display: 'flex' }}>
        {f.name} {f.status} {f.size_mb}MB
        <button disabled={f.status !== 'uploaded'} onClick={() => download()}>Download</button>
    </div>
}

interface IFileProps {
    f: TaskFileResponse;
    t: QueueItemResponse;
    token: string;
    onUploaded: () => void;
}

const OutputFile = ({ f, t, token, onUploaded }: IFileProps) => {
    const [file, setFile] = React.useState<File | undefined>();
    const [uploading, setUploading] = React.useState(false);
    const upload = () => {
        setUploading(true);
        fetch(`${API_BASE}/api/tasks/${t.parent_task_id || ''}/files/${f.id}/presigned_url`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            if (res.status === 200) {
                return res.text();
            } else {
                throw res;
            }
        })
            .then(url => {
                if (file && url) {
                    start(file, url);
                }
            }).catch(err => console.error(err));
    };

    const start = (file: File, url: string) => {
        fetch(url, { method: 'PUT', body: file })
            .then(res => {
                if (res.status === 200) {
                    console.log('uploaded');
                } else {
                    throw res;
                }
            }).catch(err => console.error(err))
            .finally(() => {
                setUploading(false);
                onUploaded();
            });
    }

    return <div style={{ display: 'flex' }}>
        <div>{f.name} ({f.status})</div>
        {f.status === 'pending' && (
            <div>
                <input
                    type='file'
                    placeholder="Upload file"
                    //value={file as any}
                    onChange={e => setFile(e.target.files ? e.target.files[0] : undefined)}
                />
                <button disabled={!file || uploading} onClick={() => upload()}>Upload</button>
            </div>
        )}
        {f.status === 'uploaded' && (
            <div>
                {f.size_mb} MB
            </div>
        )}
    </div>;
}
