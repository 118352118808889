import * as React from 'react'
import { Avatar, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuProps, MenuTrigger, Radio, RadioGroup, shorthands } from '@fluentui/react-components'
import { LastSelectedOrganisationResponse, OrganisationsResponse, OrganisationsService } from '../services/openapi';
import { AuthContext } from '../AuthContext';
import { ChevronDownRegular } from '@fluentui/react-icons';
import addNewOrgIcon from "../assets/icons/addNewOrg.svg";
import NewOrgComingsoon from './NewOrgComingsoon';
import { useLocation, useNavigate } from 'react-router-dom';
import { truncateText } from './common/textUtils';

function OrganisationList() {
    const classes = useStyles()
    const navigate = useNavigate();
    const location = useLocation();
    const { orgId, setOrgId, isOrganisationListUpdated, isAddedNewOrganisation, setIsOrganisationListUpdated, setIsAddedNewOrganisation } = React.useContext(AuthContext);
    const [lastSelectedOrganisation, setLastSelectedOrganisation] = React.useState<LastSelectedOrganisationResponse | null>(null);
    const [lastSelectedOrganisationName, setLastSelectedOrganisationName] = React.useState<String | null>(null);
    const [organisationLists, setOrganisationLists] = React.useState<OrganisationsResponse | null>(null);
    const [open, setOpen] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const restrictedPagesList = ['/projects', '/transactions', '/discover-apps', '/support', '/workFlow-library'];


    const onOpenChange: MenuProps["onOpenChange"] = (e, data) => {
        setOpen(data.open);
    };

    const fetchOrganisations = async () => {
        try {
            const [lastSelectedOrg, allMyOrganisations] = await Promise.all([
                OrganisationsService.getLastSelectedOrganisation(),
                OrganisationsService.getMyOrganisations(),
            ]);

            setOrganisationLists(allMyOrganisations);
            setIsAddedNewOrganisation(false);
            if (!lastSelectedOrg?.orgId) {
                setOrgId({ orgId: allMyOrganisations.organisations[0]?.id });
                setLastSelectedOrganisation({ orgId: allMyOrganisations.organisations[0]?.id });
                setLastSelectedOrganisationName(allMyOrganisations.organisations[0]?.name);
            } else if (isOrganisationListUpdated) {
                const selectedOrganisation = allMyOrganisations?.organisations.find(org => org?.id === orgId?.orgId);
                if (orgId?.orgId && selectedOrganisation) {
                    const newOrgId = { orgId: orgId.orgId };
                    OrganisationsService.updateLastSelectedOrganisation(newOrgId);
                    setLastSelectedOrganisation(newOrgId);
                    setLastSelectedOrganisationName(selectedOrganisation?.name);
                    setIsOrganisationListUpdated(false)
                }
            }
            else {
                setLastSelectedOrganisation(lastSelectedOrg);
                setOrgId({ orgId: lastSelectedOrg?.orgId });
                const selectedOrganisation = allMyOrganisations?.organisations.find(org => org?.id === lastSelectedOrg?.orgId);
                if (selectedOrganisation?.name) {
                    setLastSelectedOrganisationName(selectedOrganisation?.name);
                } else {
                    setLastSelectedOrganisationName(null);
                }
            }
        } catch (err) {
            console.error("Unable to fetch Organisations", err);
        }
    };

    React.useEffect(() => {
        fetchOrganisations();
    }, [isAddedNewOrganisation, isOrganisationListUpdated]);

    const handleOrgChange = async (_: React.FormEvent, data: any) => {
        const selectedOrgId = data.value;
        if (!selectedOrgId || !organisationLists) {
            return;
        }
        const selectedOrganisation = organisationLists.organisations.find(org => org.id === selectedOrgId);
        if (selectedOrganisation) {
            const newOrgId = { orgId: selectedOrganisation.id };
            try {
                await OrganisationsService.updateLastSelectedOrganisation(newOrgId);
                setOrgId(newOrgId);
                setLastSelectedOrganisationName(selectedOrganisation.name);
                setLastSelectedOrganisation(newOrgId);
                setOpen(false);
                if (!restrictedPagesList.includes(location.pathname)) {
                    navigate(`/dashboard`)
                }
                await fetchOrganisations();
            } catch (err) {
                console.error("Error updating organisation", err);
            }
        }
    };

    const toggleModal = (flag: boolean) => {
        setModalVisible(flag);
    };
    return (
        <div>
            <Menu open={open} onOpenChange={onOpenChange}>
                <MenuTrigger disableButtonEnhancement>
                    <div
                        style={{ display: "flex", width: "100%", cursor: "pointer", justifyContent: "space-between", alignItems: "center" }}>
                        {
                            lastSelectedOrganisationName && (
                                <p>{truncateText(lastSelectedOrganisationName.charAt(0).toUpperCase() + lastSelectedOrganisationName.slice(1), 5)}</p>
                            )
                        }
                        <ChevronDownRegular style={{ color: "white", fontSize: "1.3rem" }} />
                    </div>
                </MenuTrigger>

                <MenuPopover className={classes.popoverCustom}>
                    <MenuList>
                        <MenuItem style={{ borderBottom: "1px solid #F2F6FA", fontWeight: "bold", fontSize: "1rem", color: "#5C5C5C", cursor: "default", padding: ".5em" }}>
                            <h2>Organisation Settings</h2>
                        </MenuItem>
                        {
                            organisationLists?.organisations && (
                                <RadioGroup
                                    style={{
                                        height: organisationLists?.organisations?.length > 4 ? 'calc(35vh - 3rem)' : 'auto',
                                        overflow: organisationLists?.organisations?.length > 4 ? 'auto' : 'visible',
                                        scrollbarWidth: 'thin'
                                    }} value={lastSelectedOrganisation?.orgId} onChange={handleOrgChange}>
                                    {
                                        organisationLists?.organisations.map((org, index) => (
                                            <Radio labelPosition="below" className={classes.radio} style={{ width: "100%", display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", alignItems: "center" }} value={org.id} label={
                                                <>
                                                    <Avatar shape="square" size={28} name={org.name} />
                                                    <p style={{ marginLeft: ".5em", display: "inline-block" }}>{truncateText(org.name.charAt(0).toUpperCase() + org.name.slice(1), 5)}</p>
                                                </>
                                            } key={index} />
                                        ))}
                                </RadioGroup>
                            )
                        }


                        <MenuItem style={{ borderTop: "1px solid #F2F6FA", padding: ".5em" }}>
                            <div style={{ display: "flex", color: "#5C5C5C" }} onClick={() => { toggleModal(true) }}>
                                <img src={addNewOrgIcon} />
                                <h2 style={{ marginLeft: "10px" }}>Add New Organisation</h2>
                            </div>
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
            <NewOrgComingsoon
                visible={modalVisible}
                toggleModal={toggleModal}
            />
        </div >
    )
}

export default OrganisationList;
const useStyles = makeStyles({
    menulist: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        ...shorthands.gap('0.5rem'),
        "> p": {
            color: "#000",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px"
        },
        ...shorthands.textDecoration("none"),
    },
    popoverCustom: {
        width: "400px",
        left: "-10px !important",
        padding: "0",
        margin: 0,
        top: "7px !important",
        border: "1px solid #F2F6FA",
        borderRadius: "8px",
        boxShadow: "0px 4px 4px 0px #00000040",
        "& .rszfvis": {
            width: "100% !important",
            maxWidth: "100% !important"
        }
    },
    radio: {
        padding: "0",
        width: "100%",
        cursor: "pointer",
        color: "#5C5C5C",
        transition: "background-color 0.3s ease",
        "&:hover": {
            backgroundColor: "#F2F6FA",
        },
    },
})