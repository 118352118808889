import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, makeStyles } from '@fluentui/react-components';
import { DownloadReportButton } from './DownloadReportButton';

interface WorkFlowOutputProps {
    file_type: string;
    id: string;
    size_mb?: number | null;
    status: string;
    workflowId: string;
    fileName: string;
    fileDescription: string;
    canDownload: boolean;
}

function WorkFlowOutput({ file_type, id, fileName, fileDescription, workflowId, status, canDownload }: WorkFlowOutputProps) {
    const classes = useStyles();
    return <Accordion collapsible>
        <AccordionItem value={id} style={{ background: "#E2E6F6", padding: 0, borderRadius: "8px" }}>
            <AccordionHeader className={classes.accordionList}> {fileName} </AccordionHeader>
            <AccordionPanel style={{ background: "#EEF0FA", width: "100%", margin: 0, padding: ".5em" }}>
                <div style={{ padding: "16px 24px" }}>
                    <p className={classes.outputDescription}>{fileDescription}</p>
                    <div style={{ display: "flex", justifyContent: "end" }} >
                        <DownloadReportButton title={"Download"} workflowId={workflowId} fileId={id} disabled={!canDownload} />
                    </div>
                </div>
            </AccordionPanel>
        </AccordionItem>
    </Accordion>
}

export default WorkFlowOutput

const useStyles = makeStyles({
    projectHeaderText: {
        marginTop: ".5em",
    },

    accordionList: {
        '& .f1ewtqcl': {
            display: "flex",
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            paddingRight: "0"
        },
        '&>button': {
            color: "#586A84",
            fontSize: "20px",
            fontWeight: 600,
            lineHeight: "28px"
        }
    },
    outputDescription: {
        color: "#000409",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "24px",
    }
});

