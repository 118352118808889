import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DrawerBody, DrawerHeader, DrawerHeaderTitle, makeStyles, OverlayDrawer, shorthands, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from "@fluentui/react-components";
import { Dismiss24Regular, Link24Regular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { MapLayerResponse, ViewsService } from "../services/openapi";
import OpenLayers from "./OpenLayers";

const columns = [
    { columnKey: "1", label: "S.NO" },
    { columnKey: "2", label: "MAP NAME" },
    { columnKey: "3", label: "GSD" },
    { columnKey: "4", label: "AREA" },
    { columnKey: "5", label: "LINK" },
];

const rows = [
    { "s_no": "1", "map_name": "Haridwar (Parking)", "area": "280 acres", "gsd": "3 cm", "link": "82c34b62-5499-11ef-9140-42010a3ec008" },
    { "s_no": "2", "map_name": "Gurugram", "area": "3360 acres", "gsd": "10 cm", "link": "c368561c-5539-11ef-957d-42010a3ec008" },
    { "s_no": "3", "map_name": "Vegetation Area", "area": "30 acres", "gsd": "1.7 cm", "link": "e8248192-53d6-11ef-94d4-42010a3ec008" },
    { "s_no": "4", "map_name": "Apple Orchard", "area": "82 acres", "gsd": "5 cm", "link": "2ecca76e-53d7-11ef-8f29-42010a3ec008" },
    { "s_no": "5", "map_name": "Open Land", "area": "83 acres", "gsd": "3 cm", "link": "47b4448a-53d7-11ef-93ee-42010a3ec008" },
    { "s_no": "6", "map_name": "Mining Area", "area": "780 acres", "gsd": "5 cms", "link": "790034d6-53e1-11ef-9e99-42010a3ec008" }
];



const defaultStateValues = {
    visible: false,
    shareID: "",
    name: ""
}

export default function MapsDemoPage() {
    const classes = useStyles();
    const [drawerConfig, setDrawerConfig] = useState(defaultStateValues);
    const [layers, setLayers] = useState<MapLayerResponse[] | null>(null);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);

    useEffect(() => {
        const shareID = drawerConfig.shareID
        if (!shareID) {
            // timeout added for drawer animation to close
            setTimeout(() => {
                setLayers(null);
            }, 300)
            return;
        }
        ViewsService.viewSharedMap(shareID).then(data => {
            setLayers(data.mapLayers);
        }).catch(err => {
        })
    }, [drawerConfig.shareID]);

    return <div className={classes.container}>
        <Table arial-label="Features table" className={classes.table} noNativeElements size="small">
            <div className={classes.headerContainer}>
                <div className={classes.header}>Maps Demo</div>
            </div>
            <TableHeader>
                <TableRow>
                    {columns.map((column) => (
                        <TableHeaderCell key={column.columnKey} className={classes.column}>
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            <TableBody>
                {rows.map((d) => (
                    <TableRow key={d.s_no} className={classes.row}>
                        <TableCell >
                            {d.s_no}
                        </TableCell>

                        <TableCell>
                            {d.map_name}
                        </TableCell>

                        <TableCell>
                            {d.gsd}
                        </TableCell>

                        <TableCell>
                            {d.area}
                        </TableCell>

                        <TableCell>
                            <Link24Regular className={classes.link} onClick={() => { setDrawerConfig({ visible: true, shareID: d.link, name: d.map_name }) }} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>


        <OverlayDrawer
            modalType="non-modal"
            open={drawerConfig.visible}
            position="end"
            size="medium"

        >
            <DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => { setDrawerConfig(defaultStateValues) }}
                        />
                    }
                >
                    {drawerConfig.name}
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                {!layers ? <div>Loading</div> : <OpenLayers layers={layers} showFullScreenIcon={false} showLayers={false} showIdeaforgeButton={false} shareID={drawerConfig.shareID} showDownload onClickDownload={() => { setDownloadModalVisible(true) }} />}
            </DrawerBody>
        </OverlayDrawer>

        <Dialog open={downloadModalVisible}>
            <DialogSurface>
                <DialogBody>
                    <DialogContent className={classes.dialogContent}>
                        Please contact us at <a href="mailto: flyghtcloud@ideaforgetech.com">flyghtcloud@ideaforgetech.com</a>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setDownloadModalVisible(false) }}>Close</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    </div>
}


const useStyles = makeStyles({
    container: {
        ...shorthands.padding("24px")
    },
    header: {
        color: "#043957",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    headerContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    table: {
        ...shorthands.borderRadius("1rem"),
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.07)",
        paddingBottom: "18px",
        paddingRight: "32px",
        paddingTop: "32px",
        paddingLeft: "32px",
    },
    column: {
        color: "#043957",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "36px",
        paddingBottom: "18px",
        paddingTop: "32px",
    },
    row: {
        color: "#586A84",
        fontSize: "18px",
        lineHeight: "24px",
        paddingBottom: "12px",
        paddingTop: "12px",
    },
    noData: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#586A84",
        fontSize: "18px",
        paddingBottom: "12px",
        paddingTop: "24px",
    },
    "dialog-surface": {
        padding: "0px",
    },
    "body": {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "32px",
        paddingBottom: "32px",
    },
    "title": {
        color: "#3E3E3E",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    inputName: {
        marginTop: "1em",
        marginBottom: "1em",
        fontWeight: "500",
        fontSize: "1.1rem"
    },
    customInput: {
        padding: ".6em",
        background: "#ECF3FF",
        borderRadius: "4px",
    },
    link: {
        cursor: "pointer"
    },
    dialogContent: {
        color: "#3E3E3E",
        fontSize: "18px",
        fontWeight: 400,
        "> a": {
            cursor: "pointer",
            color: "#0E84E5",
            textDecoration: "none"
        }
    },

})