import { Feature } from 'ol';
import { Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style } from 'ol/style';

export const calculateOffsetPoint = (
  longitude: any,
  latitude: any,
  angle: any,
  distance: any
) => {
  const earthRadius = 6371000;
  const angleRad = (angle * Math.PI) / 180;

  const offsetLatitude =
    latitude + (distance / earthRadius) * (180 / Math.PI) * Math.cos(angleRad);
  const offsetLongitude =
    longitude +
    ((distance / earthRadius) * (180 / Math.PI) * Math.sin(angleRad)) /
      Math.cos((latitude * Math.PI) / 180);

  return [offsetLongitude, offsetLatitude];
};

export const getFovFeature = (droneInfo: any, index: number) => {
  const drone = droneInfo[index];
  const droneLon = drone.SensorLongitude;
  const droneLat = drone.SensorLatitude;
  const heading = drone.PlatformHeading;
  const horizontalFOV = drone.HorizontalFOV;
  const distance = 250;

  const leftAngle = heading - horizontalFOV / 2;
  const rightAngle = heading + horizontalFOV / 2;

  const leftPoint = calculateOffsetPoint(
    droneLon,
    droneLat,
    leftAngle,
    distance
  );
  const rightPoint = calculateOffsetPoint(
    droneLon,
    droneLat,
    rightAngle,
    distance
  );
  const centerPoint = fromLonLat([droneLon, droneLat]);

  const fovPolygon = new Polygon([
    [centerPoint, fromLonLat(leftPoint), fromLonLat(rightPoint), centerPoint],
  ]);

  const fovFeature = new Feature({
    geometry: fovPolygon,
  });

  fovFeature.setStyle(
    new Style({
      fill: new Fill({
        color: 'rgba(51, 153, 204, 0.2)',
      }),
      stroke: new Stroke({
        color: '#3399CC',
        width: 2,
      }),
    })
  );

  return fovFeature;
};
