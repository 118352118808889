import * as React from 'react';
import { API_BASE } from '../../constants';
import { Loading } from '../Loading';
import { VideoPlayer } from './VideoPlayer';

interface VideoPlayerTestProps {
    viewId: string
}

export const VideoPlayerTest = ({ viewId }: VideoPlayerTestProps) => {
    const [videoUrl, setVideoUrl] = React.useState<string | undefined>();
    const [dataUrl, setDataUrl] = React.useState<string | undefined>();


    React.useEffect(() => {
        const p1 = fetch(`${API_BASE}/api/map_layers/${viewId}/files/video.mp4?presignedUrl=true`, {
            credentials: 'include',
        })
            .then(res => {
                if (res.status !== 200) {
                    throw res;
                }
                return res.text();
            })
            // .then(base64 => decodeBase64(base64))
            .catch(err => console.error(err));
        const p2 = fetch(`${API_BASE}/api/map_layers/${viewId}/files/data.csv?presignedUrl=true`, {
            credentials: 'include'
        })
            .then(res => {
                if (res.status != 200) {
                    throw res;
                }
                return res.text();
            })
            // .then(base64 => decodeBase64(base64))
            .catch(err => console.error(err));
        Promise.all([p1, p2])
            .then(([videoUrl, dataUrl]) => {
                console.log(videoUrl, dataUrl);
                setVideoUrl(videoUrl || '');
                setDataUrl(dataUrl || '');
            })
            .catch(err => console.error(err));
    }, []);

    const decodeBase64 = (s: string) => {
        var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
        var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
        for (i = 0; i < 64; i++) { (e as any)[A.charAt(i) as any] = i as any; }
        for (x = 0; x < L; x++) {
            c = (e as any)[s.charAt(x)]; b = (b << 6) + c; l += 6;
            while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
        }
        return r;
    };

    if (!videoUrl || !dataUrl) {
        return <Loading />;
    }

    return <VideoPlayer videoUrl={videoUrl} dataUrl={dataUrl} />
}