import * as React from 'react'
import { FileTypesResponse, PreSignedUrlType, ProjectFileResponse, ProjectsService, WorkflowTemplatesService } from '../../../services/openapi'
import { ArrowDownloadRegular, DeleteRegular } from '@fluentui/react-icons';
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, makeStyles } from '@fluentui/react-components';
import { GeoJsonEditor } from '../../builtin_plugins/GeoJsonEditor';

interface FileEditModel {
    file: ProjectFileResponse,
    editorData: { file?: File, forceUpload?: boolean, }
}
interface ExistsFilesProps {
    existFile: FileEditModel;
    projectId: string;
    toggleFmWrapperModal: (flag: boolean) => void;
    toggleAddNewFilesModal: (flag: boolean) => void;
    toggleDeleteModal: (flag: boolean, flight_id: string) => void;
    load: () => void;
    notifySucess: (msg: string) => void;
    notifyError: (msg: string) => void;
}

function ExistFile({ existFile, projectId, load, toggleDeleteModal, notifySucess, notifyError, toggleFmWrapperModal, toggleAddNewFilesModal }: ExistsFilesProps) {
    const f = existFile.file;
    const em = existFile.editorData;
    const classes = useStyles();
    const [fileTypesList, setFileTypesList] = React.useState<FileTypesResponse>()
    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false)
    const [isGeoJsonEditorOpen, setIsGeoJsonEditorOpen] = React.useState(false)


    React.useEffect(() => {
        getFileTypes()
    }, [])

    const getFileTypes = () => {
        WorkflowTemplatesService.getFileTypes().then((types) => {
            setFileTypesList(types)
        }).catch(err => console.error("err getting file types"))
    }

    const getDescription = (fileType: string) => {
        const file = fileTypesList?.fileTypes.find(item => item.id === fileType);
        if (!file) {
            return fileType;
        } else {
            return file.description;
        };
    };
    const upload = (f: FileEditModel) => {
        if (!projectId) return;
        ProjectsService.getProjectFilePresignedUrl(PreSignedUrlType.PUT, projectId, f.file.id)
            .then((url) => {
                return fetch(url, {
                    method: 'PUT',
                    body: f.editorData.file,
                }).then((res) => {
                    if (res.status === 200) {
                        return ProjectsService.syncProjectFile(projectId, f.file.id)
                            .then(() => {
                                load();
                                notifySucess("File uploaded successfully to this project.");
                            })
                            .catch((err) => setError(`Could not sync file: ${err}`));
                    } else {
                        setError(`Could not upload file: ${res}`);
                    }
                });
            })
            .catch((err) => setError(`Could not get upload URL: ${err}`));
    };

    const handleDownload = (file_id: string) => {
        if (!projectId || !file_id) return;
        setLoading(true);
        ProjectsService.getProjectFilePresignedUrl(PreSignedUrlType.GET, projectId, file_id)
            .then((url) => {
                window.open(url, "_blank");
                notifySucess("File downloaded successfully.");
            })
            .catch((err) => console.error(err))
            .finally(() => setLoading(false));
    };

    const handleOnSaveFileUploader = (file: File | null) => {
        if (file) {
            const fileType = fileTypesList?.fileTypes.find(item => item.id === f.fileType);
            // todo after api modified

            // const fileExtension = file.name.split('.').pop();
            // if (fileType && fileType.extension === fileExtension) {
            //     em.file = file;
            //     upload(existFile)
            // }

            if ((fileType && (fileType.extension === "geojson" || fileType.extension === "kml"))) {
                em.file = file;
                upload(existFile)
            }
        } else {
            console.error("No file selected for New Save.");
        }
    };

    return <div key={f.id} style={{ marginTop: "1em" }}>
        <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
            <div style={{ display: "flex" }}>
                <div style={{ width: "50%" }}>
                    <p style={{ fontSize: "1rem", fontWeight: "500" }}>{f.name}</p>
                    <p style={{ fontSize: ".7rem", fontWeight: "400" }}>{`${getDescription(f.fileType)}`}</p>
                </div>
                <div style={{ width: "50%" }}>
                    {
                        (f.status === 'pending' || existFile.editorData.forceUpload !== undefined) && (
                            <div className={classes.fileInputContainer} style={{ padding: ".2em", width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <label style={{ color: "#F66B6B" }}>
                                    No file chosen
                                </label>
                                <Dialog open={isGeoJsonEditorOpen}>
                                    <Button onClick={() => { setIsGeoJsonEditorOpen(true) }}>Continue</Button>
                                    <DialogSurface style={{ minWidth: "60%", height: "75%", paddingTop: "12px" }}>
                                        <DialogBody style={{ height: "100%" }}>
                                            <DialogContent>
                                                <GeoJsonEditor
                                                    onClose={() => { setIsGeoJsonEditorOpen(false); }}
                                                    onSaveFileUploader={handleOnSaveFileUploader}
                                                    onDownloadSuccess={() => {
                                                        notifySucess("Map File successfully downloaded")
                                                    }}
                                                    isProjectFileUploader={true}
                                                    onNotifyError={notifyError}
                                                />
                                            </DialogContent>
                                        </DialogBody>
                                    </DialogSurface>
                                </Dialog>
                            </div>)
                    }
                    {(f.status === 'uploaded' || !existFile.editorData.forceUpload === undefined) && (
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <p>
                                {f.sizeMb}MB
                            </p>
                            <div style={{ display: "flex" }}>
                                <ArrowDownloadRegular className={classes.actionBtns}
                                    onClick={() => { handleDownload(f.id) }} />
                                <DeleteRegular onClick={() => {
                                    toggleDeleteModal(true, f.id)
                                }} style={{ marginLeft: ".5em" }} className={classes.actionBtns} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
}

export default ExistFile

const useStyles = makeStyles({
    title: {
        fontSize: "1.1rem",
        fontWeight: "500",
        marginBottom: ".5em"
    },
    inputField: {
        background: "#E6F0FF",
        border: "none",
        width: "100%",
        padding: ".6em",
        borderRadius: "4px"
    },
    customSelect: {
        "& .f1c1zstj": {
            background: "#E6F0FF",
            border: "none",
            width: "100%",
            borderRadius: "4px !important"
        }
    },
    fileInputContainer: {},
    fileInputLabel: {
        border: "1px solid #586A84",
        padding: ".5em",
        width: "45%",
        textAlign: "center",
        color: "#586A84",
        fontWeight: 600,
        borderRadius: "6px"
    },
    fileInput: {
        display: 'none',
    },
    actionBtns: {
        fontSize: "1.3rem",
        color: "#727272",
        cursor: "pointer",
        '&:hover': {
            color: "#000000"
        },
    }

})