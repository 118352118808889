import {
    Body1,
    Button,
    Caption1,
    Card,
    CardFooter,
    CardHeader,
    CardPreview,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Menu,
    MenuItem,
    MenuList,
    MenuPopover,
    MenuTrigger,
    Table,
    TableBody, TableCell, TableCellLayout,
    TableColumnDefinition,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Toast,
    ToastTitle,
    Toaster,
    createTableColumn,
    makeStyles, shorthands,
    tokens,
    useTableFeatures,
    useToastController,
} from "@fluentui/react-components";
import { DocumentRegular, SendRegular } from "@fluentui/react-icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import mapIcon from "../../assets/icons/map.svg";
import verticalMode from "../../assets/icons/more_actions.svg";
import projectHeaderIcon from "../../assets/icons/project_header.svg";
import sendIcon from "../../assets/icons/send.svg";
import { AuthContext } from "../../AuthContext";
import { API_BASE } from "../../constants";
import { FeatureDemosService, ProjectAccessResponse, ProjectResponse, ProjectsService } from "../../services/openapi";
import AskAdministrator from "../common/AskAdministrator";
import OrgInactive from "../common/OrgInactive";
import { useOrganisationCheck } from "../hooks/useOrganisationCheck";
import { Loading } from "../Loading";
import { TourContext, sourceTypes } from "../TourContext";
import EmptyState from "./common/EmptyState";
import { Pagination } from "./common/Pagination";
import ViewOptions from "./common/ViewOptions";
import DeleteProjectModal from "./DeleteProjectModal";
import EditProjectDetailsModal from "./EditProjectDetailsModal";
import FileManageWrapper from "./fileManage/FileManageWrapper";
import { FlyghtCloudDemoModal } from "./FlyghtCloudDemoModal";
import { TourGuideCompletedModal } from "./TourGuideCompletedModal";
import { TourStartModal } from "./TourStartModal";


export const ProjectsDashboard = () => {
    const classes = useStyles();
    const { orgId } = useContext(AuthContext);
    const [page, setPage] = React.useState(0);
    const [hasNext, setHasNext] = React.useState(false);
    const [filteredData, setFilteredData] = React.useState<
        ProjectResponse[] | null
    >();
    const [error, setError] = React.useState("");
    const [viewMode, setViewMode] = React.useState<string>("grid");
    const { dispatchToast } = useToastController("success");
    const { startTour, tourCompletedModalVisible, hideCompletedModal, source, setTourSource, isPrevious } = React.useContext(TourContext);
    const [isDemoModalVisible, setIsDemoModalVisible] = useState(false);
    const [isTourStartModalVisible, setIsTourStartModalVisible] = useState(false)
    const { noOrganisation, isOrgActive } = useOrganisationCheck();
    const [params, setParams] = useSearchParams();

    React.useEffect(() => {
        if (source && source === sourceTypes.startOnboarding && !isPrevious) {
            setViewMode("grid")
            startTour({ run: true, stepIndex: 0 });
            setTourSource(sourceTypes.startOnboarding);
            return;
        }
        if (!tourCompletedModalVisible && !isPrevious) {
            FeatureDemosService.getAllFeatureDemos().then((features) => {
                if (!(features?.featureDemos.find(item => item.name === "projects"))) {
                    return;
                } else {
                    FeatureDemosService.getAllFeatureDemoViews().then((features) => {
                        if (!features || features?.viewedFeatureDemos.length === 0 || !(features?.viewedFeatureDemos.find(item => item.name === "projects"))) {
                            setViewMode("grid");
                            setIsTourStartModalVisible(true);
                        }
                    });
                }
            });
        }
    }, [source, tourCompletedModalVisible])

    useEffect(() => {
        if (orgId?.orgId && isOrgActive !== null) {
            const pageNumber = params.get("page")
            if (pageNumber && !isNaN(Number(pageNumber))) {
                setPage(Number(pageNumber))
                load(Number(pageNumber), orgId?.orgId)
            } else {
                setParams({ page: "0" })
                load(0, orgId?.orgId);
            }
        }
    }, [orgId, isOrgActive, params.get("page")]);

    const load = (page: number, orgId: string) => {
        ProjectsService.listProjects(page, 8, orgId)
            .then((projects) => {
                setFilteredData(projects.projects);
                setHasNext(projects.projects.length >= 8);
                setError("")
            })
            .catch((err) => {
                setError(`Could not fetch projects: ${err}`)
            });
    };

    const prev = () => {
        setParams({ page: String(page - 1) })
        setPage(page - 1);
        if (orgId?.orgId) {
            load(page - 1, orgId?.orgId);
        }
    };

    const next = () => {
        setParams({ page: String(page + 1) });
        setPage(page + 1);
        if (orgId?.orgId) {
            load(page + 1, orgId?.orgId);
        }

    };
    const handleGridListView = (val: string) => {
        setViewMode(val);
    };

    const notifySucess = (msg: string) => dispatchToast(
        <Toast
            style={{ background: "#DFF6DD", maxWidth: "600px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{msg}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const notifyError = (err: string) => dispatchToast(
        <Toast
            style={{ background: "#FDE7E9", width: "500px" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }}>{err}</ToastTitle>
        </Toast>,
        { intent: "error" }
    );

    const onTourStartSkip = () => {
        FeatureDemosService.addFeatureDemoView({ name: "projects" }).catch((err) => { console.error("Error marking feature view", err) });
        setIsTourStartModalVisible(false)
    }

    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={projectHeaderIcon} alt="Projects" />
                    Projects
                </div>
            </div>

            <FlyghtCloudDemoModal visible={isDemoModalVisible} hideDemoModal={() => { setIsDemoModalVisible(false); setTimeout(() => { setIsTourStartModalVisible(true) }, 200) }} />

            <TourStartModal visible={isTourStartModalVisible} onSkip={onTourStartSkip} onContinue={() => { setIsTourStartModalVisible(false); startTour({ run: true, stepIndex: 0 }) }} />

            <TourGuideCompletedModal visible={Boolean(tourCompletedModalVisible)} hideModal={hideCompletedModal} />

            {noOrganisation ? <AskAdministrator />
                :
                <div
                    style={{
                        width: "100%",
                        padding: "1em 2em",
                        height: "calc(92vh - 3rem)",
                        overflow: (filteredData?.length ?? 0) >= 1 ? "auto" : "hidden",
                        scrollbarWidth: "thin",
                        position: "relative",
                    }}
                >
                    <div className={classes.subHeader}>
                        Projects
                        <ViewOptions
                            handleGridListView={handleGridListView}
                            viewIcon={viewMode}
                        />
                    </div>
                    {error && <div>{error}</div>}

                    {!filteredData && <Loading />}

                    {filteredData && viewMode === "grid" && filteredData.length === 0 && (
                        <NoProjects />

                    )}

                    <div style={{ display: "flex", flexDirection: "column", height: "90%", marginTop: "24px" }}>
                        <div style={{ flex: 1 }}>
                            {filteredData && viewMode === "grid" && (
                                <div className={classes.grid}>
                                    {filteredData.map((p, index) => {
                                        const id = index === 0 ? "project-card" : undefined
                                        return <div key={index} id={id}>
                                            <div style={{}}>
                                                <ProjectCard key={p.id} p={p} load={load} notifySucess={notifySucess} notifyError={notifyError} currentPage={page} />
                                            </div>
                                        </div>
                                    })}
                                </div>
                            )}

                            {filteredData && viewMode === "list" && (
                                <ProjectTable projectsData={filteredData} load={load} notifySucess={notifySucess} notifyError={notifyError} currentPage={page} />
                            )}
                        </div>
                        <div style={{ zIndex: 999999999 }} className={classes.toasterLayer}>
                            <Toaster inline toasterId={"success"} position="bottom" />
                        </div>

                        <div style={{ marginTop: ".5em" }}>
                            <Pagination currentPage={page + 1} hasNext={hasNext} hasPrev={page > 0} onClickPrev={prev} onClickNext={next} />
                        </div>
                    </div>
                </div >
            }
        </>
    );
};

interface ProjectResponseProps {
    projectData: ProjectResponse;
    load: (page: number, orgId: string) => void;
    notifySucess: (msg: string) => void;
    notifyError: (msg: string) => void;
    currentPage: number
}

const MoreFeature = ({ projectData, load, notifySucess, currentPage, notifyError }: ProjectResponseProps) => {
    const classes = useStyles();
    const [editModalVisible, setEditModalVisible] = React.useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = React.useState(false);
    const [manageFilesModalVisible, setManageFilesModalVisible] = React.useState(false);
    const [project, setProject] = React.useState<ProjectResponse | null>(null);
    const [projectId, setProjectId] = React.useState<string | null>();
    const [projectAccess, setProjectAccess] = useState<ProjectAccessResponse | null>(null)

    useEffect(() => {
        ProjectsService.checkUserProjectAccess(projectData.id).then(permissions => {
            setProjectAccess(permissions)
        }).catch(err => console.error("Unable to fetch project access"))
    }, [])
    const canEdit = projectAccess && projectAccess.canEditProject;
    const canArchive = projectAccess && projectAccess.canArchiveFlight;

    const getProjectSummary = (pid: string) => {
        ProjectsService.getProjectSummary(pid)
            .then((ps) => setProject(ps))
            .catch((err) => console.error(err));
    };

    React.useEffect(() => {
        if (!projectId) return;
        getProjectSummary(projectId);
    }, [projectId]);

    const toggleEditModal = (flag: boolean) => {
        setEditModalVisible(flag);
        if (projectId && !flag) {
            getProjectSummary(projectId);
        }
    };

    const toggleDeleteModal = (flag: boolean) => {
        setDeleteModalVisible(flag);
        if (projectId && !flag) {
            getProjectSummary(projectId);
        }
    };

    const toggleManageFilesModal = (flag: boolean) => {
        setManageFilesModalVisible(flag);
    };


    const handleMoreFeature = () => {
        setProjectId(projectData.id);
    };



    return (
        <div>
            <Menu>
                {projectData.archived ? (
                    <div className={classes.new}>
                        <img
                            alt="More"
                            style={{ width: "18px" }}
                            className={
                                projectData.archived
                                    ? classes.disabledOverlay
                                    : classes.nonDisabledOverlay
                            }
                            src={verticalMode}
                        />
                    </div>
                ) : (
                    <MenuTrigger disableButtonEnhancement>
                        <div className={classes.new}>
                            <img
                                alt="More"
                                style={{ width: "18px" }}
                                onClick={projectData.archived ? undefined : handleMoreFeature}
                                className={
                                    projectData.archived
                                        ? classes.disabledOverlay
                                        : classes.nonDisabledOverlay
                                }
                                src={verticalMode}
                            />
                        </div>
                    </MenuTrigger>
                )}
                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            className={classes.flexContent}
                            style={{ padding: 0, opacity: canEdit ? "100%" : "40%" }}
                            onClick={() => {
                                canEdit && toggleEditModal(true);
                            }}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            className={classes.flexContent}
                            style={{ padding: 0, opacity: canArchive ? "100%" : "40%" }}
                            onClick={() => {
                                canArchive && toggleDeleteModal(true);
                            }}
                        >
                            Delete
                        </MenuItem>{" "}
                        <MenuItem
                            className={classes.flexContent}
                            style={{ padding: 0, opacity: canEdit ? "100%" : "40%" }}
                            onClick={() => {
                                if (canEdit) {
                                    setProjectId(projectData.id)
                                    toggleManageFilesModal(true)
                                }
                            }}
                        >
                            Manage Project Files
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>


            {project ? (
                <EditProjectDetailsModal
                    load={load}
                    visible={editModalVisible}
                    toggleModal={toggleEditModal}
                    project={project ?? undefined}
                />
            ) : null}
            {project ? (
                <DeleteProjectModal
                    load={load}
                    visible={deleteModalVisible}
                    toggleModal={toggleDeleteModal}
                    project={project ?? undefined}
                    currentPage={currentPage}
                />
            ) : null}
            {project && manageFilesModalVisible && projectId && (
                <FileManageWrapper
                    projectId={projectId}
                    fmWrapperVisible={manageFilesModalVisible}
                    toggleFmWrapperModal={setManageFilesModalVisible}
                    notifySucess={notifySucess}
                    notifyError={notifyError}
                />
            )}
        </div>
    );

};

interface ProjectCardProps {
    p: ProjectResponse;
    load: (page: number, orgId: string) => void;
    notifySucess: (msg: string) => void;
    notifyError: (msg: string) => void;
    currentPage: number;
}

const ProjectCard = ({ p, load, notifySucess, notifyError, currentPage }: ProjectCardProps) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [orgInactiveState, setOrgInactiveState] = React.useState(false);
    const { isOrgActive } = useOrganisationCheck()
    const truncateText = (text: string, maxLength: number) => {
        const words = text.split(' ');
        if (words.length > maxLength) {
            return words.slice(0, maxLength).join(' ') + '...';
        } else {
            return text;
        }
    };

    const toggleOrgInactiveModal = (flag: boolean) => {
        setOrgInactiveState(flag);
    };

    return (
        <>
            <div className={classes.cardContainer} style={{ position: "relative", cursor: p.archived ? "pointer" : "default" }}>
                <Card
                    className={` ${(p.archived || !isOrgActive) ? classes.disabledCard : classes.card}`}
                    style={{ borderRadius: "16px" }}
                >
                    <CardPreview style={{ height: "calc(.2rem + 15vh)" }}
                        className={
                            (p.archived || !isOrgActive) ? classes.disabledOverlay : classes.nonDisabledOverlay
                        }
                        onClick={() => {
                            if (!isOrgActive) {
                                setOrgInactiveState(true);
                            } else if (!p.archived && Boolean(p.numMaps)) {
                                navigate(`${p.id}/views`)
                            } else if (!p.archived && !Boolean(p.numMaps)) {
                                navigate(`/projects/${p.id}`)
                            }
                        }}
                    >
                        <img style={{ cursor: "pointer" }}
                            className={classes.gridImg}
                            src={`${API_BASE}/api/projects/${p.id}/thumbnail`}
                            alt="Project Thumbnail"
                        />
                    </CardPreview>
                    <div style={{
                        minHeight: "calc(0rem + 8vh)", marginTop: "1em", overflow: "auto", scrollbarWidth: "none",
                        msOverflowStyle: "none"
                    }}>
                        <CardHeader
                            style={{ cursor: p.archived ? "default" : "pointer" }}
                            onClick={() => {
                                if (!isOrgActive) {
                                    setOrgInactiveState(true);
                                } else if (!p.archived) {
                                    navigate(`/projects/${p.id}`);
                                }
                            }}
                            header={
                                <Body1>
                                    <div
                                        style={{
                                            fontWeight: "600",
                                            fontSize: "1.3rem",
                                            color: "#606060",
                                        }}
                                    >

                                        {truncateText(p.name, 3)}
                                    </div>
                                </Body1>
                            }
                            description={
                                <Caption1 style={{ margin: ".5em 0" }}>
                                    <div style={{ color: "#8E8E8E", fontSize: "0.95em" }}>
                                        Last updated: {moment(p.lastUpdated).fromNow()}
                                    </div>
                                </Caption1>
                            }
                            id="project-card-title"
                        />
                    </div>
                    <CardFooter
                        className={classes.flexContent}
                        style={{
                            borderTop: "1px solid #E3E9F2",
                            paddingTop: ".5em",
                            opacity: isOrgActive ? 1 : 0.5,
                            pointerEvents: isOrgActive ? "auto" : "none"
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", gap: "24px" }}>
                            <div className={classes.flexContent} id="project-card-totalflights">
                                <div className={classes.flexContent} style={{ cursor: "pointer" }} onClick={() => { navigate(`/projects/${p.id}`) }}>
                                    <img src={sendIcon} alt="view maps" />
                                    <span className={classes.cardSubText}>{p.numFlights}</span>
                                </div>
                            </div>

                            {Boolean(p.numMaps) && <div className={classes.flexContent} style={{ cursor: "pointer" }} id="project-card-viewmaps">
                                <Link className={classes.flexContent} to={`${p.id}/views`} style={{ textDecoration: "none" }}>
                                    <img src={mapIcon} alt="view maps" />
                                </Link>
                            </div>}
                        </div>
                        <MoreFeature projectData={p} load={load} notifySucess={notifySucess} currentPage={currentPage} notifyError={notifyError} />
                    </CardFooter>
                </Card>

                {p.archived && <DeletingProjectText />}

            </div >

            <Dialog
                open={showDeleteModal}
                onOpenChange={(_, d) => { setShowDeleteModal(d.open) }}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Deleting Project</DialogTitle>
                        <DialogContent>
                            This may take some time depending on the size of the project. Please wait while we complete the process.
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={() => setShowDeleteModal(false)}>Close</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog >
            {
                orgInactiveState && <OrgInactive
                    visible={orgInactiveState}
                    toggleModal={toggleOrgInactiveModal}
                />
            }
        </>
    );
};

const DeletingProjectText = () => {
    const classes = useStyles();
    const [text, setText] = useState("");

    useEffect(() => {
        const texts = [
            "", ".", "..", "..."
        ];
        let index = 0;

        const interval = setInterval(() => {
            index = (index + 1) % texts.length;
            setText(texts[index]);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    return <div className={classes.textContainer}>
        <p className={classes.archiveText}>
            Deleting Project<span className={classes.dots}>{text}</span>
        </p>
    </div>
};

interface ProjectTableProps {
    projectsData: ProjectResponse[];
    load: (page: number, orgId: string) => void;
    notifySucess: (msg: string) => void;
    notifyError: (msg: string) => void;
    currentPage: number;
}

const ProjectTable: React.FC<ProjectTableProps> = ({ projectsData, notifySucess, notifyError, load, currentPage }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { noOrganisation, isOrgActive } = useOrganisationCheck();
    const [orgInactiveState, setOrgInactiveState] = React.useState(false);

    type ProjectNameCell = {
        label: string;
        icon: JSX.Element;
    };
    type LastUpdatedCell = {
        label: string;
    };
    type NumOfFlightsCell = {
        label: number;
    };
    type CompletedWorkflowsCell = {
        label: number;
    };
    type NumMapsCell = {
        label: number;
    };
    type NumReportsCell = {
        label: number;
    };
    type OngoingWorkflowsCell = {
        label: number;
    };
    type CreatedAtCell = {
        label: string;
    };
    type CreatedByCell = {
        label: string;
    };
    type OrganisationIdCell = {
        label: string;
    };
    type UpdatedAtCell = {
        label: string;
    };
    type ArchivedCell = {
        label: boolean;
    };
    type IdCell = {
        label: string;
    };
    type Item = {
        name: ProjectNameCell;
        lastUpdated: LastUpdatedCell;
        numFlights: NumOfFlightsCell;
        completedWorkflows: CompletedWorkflowsCell;
        createdAt: CreatedAtCell;
        createdBy: CreatedByCell;
        numMaps: NumMapsCell;
        numReports: NumReportsCell;
        ongoingWorkflows: OngoingWorkflowsCell;
        organisationId: OrganisationIdCell;
        updatedAt: UpdatedAtCell;
        archived: ArchivedCell;
        id: IdCell;
    };
    const items: Item[] = projectsData.map((project) => ({
        name: {
            label: project.name,
            icon: (
                <img
                    style={{ width: "45px", borderRadius: "5px", height: "30px" }}
                    src={`${API_BASE}/api/projects/${project.id}/thumbnail`}
                    alt="Thumbnail"
                />
            ),
        },
        lastUpdated: { label: moment(project.lastUpdated).fromNow() },
        numFlights: { label: project.numFlights, icon: <DocumentRegular /> },
        completedWorkflows: { label: project.completedWorkflows },
        createdAt: { label: project.createdAt },
        createdBy: { label: project.createdBy },
        numMaps: { label: project.numMaps },
        numReports: { label: project.numReports },
        ongoingWorkflows: { label: project.ongoingWorkflows },
        organisationId: { label: project.organisationId },
        updatedAt: { label: project.updatedAt },
        archived: { label: !!project.archived },
        id: { label: project.id },
    }));

    const columns: TableColumnDefinition<Item>[] = [
        createTableColumn<Item>({
            columnId: "ProjectName",
        }),
        createTableColumn<Item>({
            columnId: "LastUpdated",
        }),
        createTableColumn<Item>({
            columnId: "NumOfFlights",
        }),
    ];
    const { getRows } = useTableFeatures({
        columns,
        items,
    });
    const rows = getRows();

    const toggleOrgInactiveModal = (flag: boolean) => {
        setOrgInactiveState(flag);
    };

    return (<>
        <Table sortable aria-label="Table with controlled sort">
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>Project Name</TableHeaderCell>
                    <TableHeaderCell>Last updated</TableHeaderCell>
                    <TableHeaderCell>Flights</TableHeaderCell>
                </TableRow>
            </TableHeader>

            <TableBody>
                {noOrganisation ? <AskAdministrator />
                    : <>
                        {rows.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={3}>
                                    <EmptyState stateMessage="project" />
                                </TableCell>
                            </TableRow>
                        ) : (
                            rows.map(({ item }) => (
                                <TableRow
                                    key={item.name.label}
                                    className={`${(item.archived.label || !isOrgActive) ? classes.disabledCard : ""}`}
                                >
                                    <TableCell>
                                        <TableCellLayout media={
                                            <span
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    console.log("iiii", item)
                                                    if (!item.archived.label && item.numMaps.label) {
                                                        navigate(`${item.id.label}/views`)
                                                    } else if (!item.archived.label && !item.numMaps.label) {
                                                        navigate(`/projects/${item.id.label}`)
                                                    }
                                                }}
                                            >
                                                {item.name.icon}
                                            </span>
                                        }>
                                            <p
                                                style={{
                                                    cursor: item.archived.label ? "default" : "pointer",
                                                    color: "#606060",
                                                }}
                                                onClick={() => {
                                                    if (!isOrgActive) {
                                                        setOrgInactiveState(true);
                                                    } else if (!item.archived.label) {
                                                        navigate(`/projects/${item.id.label}`)
                                                    }
                                                }}
                                            >
                                                {item.name.label}
                                            </p>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell>{item.lastUpdated.label}</TableCell>
                                    <TableCell>
                                        <SendRegular style={{ margin: "0 .5em" }} />
                                        <span>{item.numFlights.label}</span>
                                    </TableCell>
                                    <TableCell
                                        className={classes.flexContent}
                                        style={{
                                            textAlign: "end", position: "absolute", right: "2%",
                                            opacity: isOrgActive ? 1 : 0.5,
                                            pointerEvents: isOrgActive ? "auto" : "none"
                                        }}
                                    >
                                        <MoreFeature
                                            projectData={{
                                                completedWorkflows: item.completedWorkflows.label,
                                                createdAt: item.createdAt.label,
                                                createdBy: item.createdBy.label,
                                                id: item.id.label,
                                                lastUpdated: item.lastUpdated.label,
                                                name: item.name.label,
                                                numFlights: item.numFlights.label,
                                                numMaps: item.numMaps.label,
                                                numReports: item.numReports.label,
                                                ongoingWorkflows: item.ongoingWorkflows.label,
                                                organisationId: item.organisationId.label,
                                                updatedAt: item.updatedAt.label,
                                                archived: item.archived.label,
                                            }}

                                            load={load} notifySucess={notifySucess}
                                            notifyError={notifyError}
                                            currentPage={currentPage}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </>}
            </TableBody>
        </Table >
        {
            orgInactiveState && <OrgInactive
                visible={orgInactiveState}
                toggleModal={toggleOrgInactiveModal}
            />
        }
    </>
    );
};

const NoProjects = () => {
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <EmptyState stateMessage="project" />
        </div>
    );
};

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    description: {
        ...shorthands.margin(0, 0, "12px"),
    },
    flexContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "> span": {
            marginLeft: ".2em",
        },
    },
    card: {
        width: "100%",
        maxWidth: "100%",
        position: "relative",
        boxShadow: "none",
        ...shorthands.border("1px", "solid", "#E3E9F2"),
        backgroundColor: "#FBFBFB",
        ':hover': {
            boxShadow: "0 2px 4px rgba(0,0,0,0.14)"
        },

    },
    cardContainer: {
        "& .fcvsdzp": {
            rowGap: "0px !important"
        }
    },

    text: {
        ...shorthands.margin(0),
    },
    footerIcons: {
        width: "18px",
    },
    gridImg: {
        height: "100%",
        width: "100%",
        objectFit: "cover"
    },
    actionsContainer: {
        display: "flex",
        ...shorthands.gap("2.5rem"),
    },
    new: {
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
        ...shorthands.gap("0.5rem"),
        color: tokens.colorNeutralBackground1,
        fontSize: "18px",
        lineHeight: "22px",
        cursor: "pointer",
    },
    grid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(20%, 1fr))",
        gridGap: "2em",
    },
    subHeader: {
        fontWeight: "600",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1.5em",
        marginBottom: ".2em",
    },
    disabledCard: {
        opacity: "0.5",
    },
    disabledOverlay: {
        cursor: "default",
    },
    nonDisabledOverlay: {
        cursor: "pointer",
    },
    cardSubText: {
        color: "#586A84",
        fontSize: "14px",
        lineHeight: "24px",
    },
    textContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "300px",
        textAlign: "center",
        whiteSpace: "nowrap",
    },
    archiveText: {
        color: "#000",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "36px",
    },
    dots: {
        display: "inline-block",
        width: "1.5em",
        textAlign: "left",
    },
    orgMessageContainer: {
        display: "flex",
        alignItems: "center",
        height: "50%",
        justifyContent: "center",
        textAlign: "center",
        color: "#586A84",
        fontSize: "20px",
        fontWeight: 600
    },
    toasterLayer: {
        '& .f1euv43f': {
            width: "45% !important",
            left: " calc(41% + 0px) !important"
        },
    }
});
