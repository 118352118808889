import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Toast, ToastTitle, Toaster, makeStyles, mergeClasses, shorthands, useId, useToastController } from "@fluentui/react-components";
import { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "../assets/icons/arrow_down.svg";
import InfoIcon from "../assets/icons/info.svg";
import MapShareIcon from "../assets/icons/map_share.svg";
import ToastSuccessIcon from "../assets/icons/toast_success.svg";
import { FlightResponse, ViewsService } from "../services/openapi";
import { PrimaryButton2 } from "./common/PrimaryButton2";
import ShareAnyoneIcon from "../assets/icons/link.svg";
import DropdownDownIcon from "../assets/icons/arrow_down.svg";
import closeBtn from "../assets/icons/modalClose.svg"

interface Props {
    flight?: FlightResponse;
    shareID: string | null;
    layerID: string[] | null;
    closeShare: () => void;
    onChageAccess: (accessType: string) => void;
    shareAccess: string;
}

export function MapShare(props: Props) {
    const { flight, shareID, layerID, closeShare, onChageAccess, shareAccess } = props;
    const [shareExpire, setShareExpire] = useState("48 hours");
    const [shareExpireTime, setShareExpireTime] = useState(48);
    const successToastID = useId("success");
    const { dispatchToast: dispatchSuccessToast } = useToastController(successToastID);
    const shareContainerRef = useRef<HTMLDivElement>(null);

    const classes = useStyles();

    const expiryOptions = [
        { time: 24, text: "24 Hours" },
        { time: 48, text: "48 Hours" },
        { time: 24 * 7, text: "1 Week" },
        { time: 24 * 14, text: "2 Weeks" },
    ];

    const successToast = (message: string) => dispatchSuccessToast(
        <Toast
            style={{ background: "rgba(223, 246, 221, 1)", width: "100%" }}>
            <ToastTitle style={{ fontSize: "14px", fontWeight: 400 }} media={<img src={ToastSuccessIcon} alt="success icon" style={{ marginRight: "0.25rem" }} />}>{message}</ToastTitle>
        </Toast>,
        { intent: "success" }
    );

    const updateShareExpiryTime = (time: number, text: string, layerIds: string[] | null) => {
        if (shareID && layerID) {
            ViewsService.updateMapShareInfo({
                shareId: shareID,
                validTime: time,
                isPublic: shareAccess !== "private",
                mapLayers: layerIds
            }).then(() => {
                setShareExpire(text);
            }).catch(err => console.error("Error updating expiry time", err))
        } else {
            console.error("No Share ID found!")
        }
    }

    useEffect(() => {
        if (shareExpireTime !== 0) {
            updateShareExpiryTime(shareExpireTime, shareExpire, layerID);
        }
    }, [shareExpire, shareExpireTime, shareAccess]);

    return <>
        <div className={classes.shareContainer} ref={shareContainerRef}>
            <img src={MapShareIcon} alt="share" className={classes.shareImage} />
            <img src={closeBtn} onClick={() => { closeShare() }} style={{ width: "4%", cursor: "pointer", position: "absolute", right: "2%", top: "2%" }} />
            <div className={classes.shareContentContainer}>
                <div className={classes.shareHeader}>{flight ? `Share ${flight?.name} map` : `Share map`}</div>
                <div className={classes.shareSubHeader}>Share this map with anyone by sending them a link. </div>
            </div>

            <div className={classes.shareContentContainer}>
                <div className={classes.shareSubHeaderBold}>Access to this map</div>
                <Menu>
                    <MenuTrigger>
                        <div className={classes.shareAccessDropDownContainer}>
                            <div className={classes.shareAccessDropdownContent}>
                                <img src={ShareAnyoneIcon} alt="share anyone" />
                                <div className={classes.shareSubHeader}> {shareAccess === "private" ? "Anyone with the private link" : "Anyone with the link"}</div>
                            </div>
                            <img src={DropdownDownIcon} alt="down" style={{ width: "4%" }} />
                        </div>
                    </MenuTrigger>

                    <MenuPopover style={{ minWidth: "400px" }}>
                        <MenuList>
                            <MenuItem style={{ minWidth: "390px" }} onClick={() => {
                                onChageAccess("private")
                            }}>
                                <div className={classes.shareAccessDropdownContent}>
                                    <div className={classes.shareSubHeader}>Anyone with the private link</div>
                                </div>
                            </MenuItem>
                            <MenuItem style={{ minWidth: "390px" }} onClick={() => {
                                onChageAccess("public")
                            }}>
                                <div className={classes.shareAccessDropdownContent}>
                                    <div className={classes.shareSubHeader}>Anyone with the link</div>
                                </div>
                            </MenuItem>
                        </MenuList>
                    </MenuPopover>
                </Menu>

                <div className={mergeClasses(classes.shareSubHeader, classes.shareInfoContianer)}> <img src={InfoIcon} alt="share" />
                    {shareAccess === "private" ? "Users with this link will gain access after logging in to Flyght Cloud." : "Anyone with link, even outside your organisation, can view this map."}
                </div>
            </div>
            <div>
                <div className={classes.shareContentContainer}>
                    <div className={classes.shareSubHeaderBold}>Your map's {shareAccess === "private" ? "private" : "public"} link</div>
                    <div className={classes.shareLinkContainer}>
                        <p className={classes.shareSubHeader}>{`${window.location.host}/share/${shareID}`}</p>
                        <PrimaryButton2 label="Copy" onClick={() => {
                            navigator.clipboard.writeText(`${window.location.host}/share/${shareID}`);
                            if (flight && flight.name) {
                                successToast(`${flight.name} map link copied successfully`);
                            } else {
                                successToast(`${shareAccess === "private" ? "Private" : "Public"} map link is copied successfully. `);
                            }
                        }} />
                    </div>
                </div>

                <div className={classes.shareContentContainer} style={{ marginTop: "2em" }}>
                    <div className={classes.shareSubHeaderBold}>{shareAccess === "private" ? "Private" : "Public"} map settings</div>
                    <div className={classes.shareExpireContainer} >
                        <p className={classes.shareSubHeader}>Map Link expires after</p>
                        <Menu>
                            <MenuTrigger >
                                <div className={classes.expireDropDownContainer}>
                                    <div className={classes.expireDropDownText}>{shareExpire}</div>
                                    <img src={ArrowDownIcon} alt="down" />
                                </div>
                            </MenuTrigger>

                            <MenuPopover >
                                <MenuList>
                                    <MenuList>
                                        {expiryOptions.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => {
                                                    setShareExpireTime(option.time);
                                                    setShareExpire(option.text);
                                                }}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </MenuList>
                            </MenuPopover>
                        </Menu>
                    </div>
                </div>
                <Toaster toasterId={successToastID} position="bottom" limit={1} />
            </div>
        </div>
    </>
}

const useStyles = makeStyles({
    shareContainer: {
        width: "75%",
        padding: "32px",
        display: 'flex',
        flexDirection: "column",
        position: "relative",
        ...shorthands.gap("35px"),
        overflowY: "auto"
    },
    shareImage: {
        width: "48px",
        height: "48px",
    },
    shareHeader: {
        color: "#000",
        fontSize: "22px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "28px"
    },
    shareContentContainer: {
        display: "flex",
        flexDirection: "column",
        ...shorthands.gap("8px"),
    },
    shareSubHeader: {
        color: "#000",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "24px"
    },
    shareSubHeaderBold: {
        color: "#000",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 600,
    },
    shareInfoContianer: {
        display: "flex",
        ...shorthands.gap("8px"),
    },
    shareLinkContainer: {
        display: "flex",
        padding: "8px",
        justifyContent: "space-between",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        backgroundColor: "#F3F6F9",
    },
    shareExpireContainer: {
        display: "flex",
        alignItems: "center",
        ...shorthands.gap("16px"),
        ...shorthands.padding("24px", "16px"),
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(125, 143, 169, 0.20)")

    },
    expireDropDownContainer: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ...shorthands.gap("24px"),
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "4px",
        paddingBottom: "4px",
        ...shorthands.borderRadius("3px"),
        ...shorthands.border("1px", "solid", "rgba(0, 0, 0, 0.06)"),
        backgroundColor: "rgba(255, 255, 255, 0.70)",
    },
    expireDropDownText: {
        color: "rgba(0, 0, 0, 0.90)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px"
    },
    shareAccessDropDownContainer: {
        width: "400px",
        ...shorthands.padding("12px"),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        ...shorthands.borderRadius("8px"),
        ...shorthands.border("1px", "solid", "rgba(125, 143, 169, 0.30)"),
        cursor: "pointer",
    },
    shareAccessDropdownContent: {
        display: "flex",
        ...shorthands.gap("16px"),
    },
})