import * as React from 'react';
import ReactGA from "react-ga4";
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthContext } from './AuthContext';
import { TourProvider } from './components/TourContext';
import { GTM_ID } from './constants';
import { LastSelectedOrganisationResponse, UserResponse } from './services/openapi';

try {
  ReactGA.initialize(GTM_ID);
} catch {
  console.error("GTM ID not found!")
}

const AppWrapper = () => {
  const [me, setMe] = React.useState<UserResponse | null>(null);
  const [isNewProjectOpen, setIsNewProjectOpen] = React.useState<boolean | false>(false);
  const [orgId, setOrgId] = React.useState<LastSelectedOrganisationResponse | null>(null);
  const [isAddedNewOrganisation, setIsAddedNewOrganisation] = React.useState<boolean | false>(false);
  const [isOrganisationListUpdated, setIsOrganisationListUpdated] = React.useState<boolean | false>(false);

  return (
    <AuthContext.Provider value={{ me, setMe, isNewProjectOpen, setIsNewProjectOpen, orgId, setOrgId, isAddedNewOrganisation, setIsAddedNewOrganisation, isOrganisationListUpdated, setIsOrganisationListUpdated }}>
      <BrowserRouter>
        <TourProvider>
          <App />
        </TourProvider>
      </BrowserRouter>
    </AuthContext.Provider >
  );
};


export default AppWrapper;


