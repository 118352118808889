/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateOrganisationRequest } from '../models/CreateOrganisationRequest';
import type { CreditsRequest } from '../models/CreditsRequest';
import type { LastSelectedOrganisationRequest } from '../models/LastSelectedOrganisationRequest';
import type { LastSelectedOrganisationResponse } from '../models/LastSelectedOrganisationResponse';
import type { OrganisationResponse } from '../models/OrganisationResponse';
import type { OrganisationsResponse } from '../models/OrganisationsResponse';
import type { Project } from '../models/Project';
import type { RoleRequest } from '../models/RoleRequest';
import type { RoleResponse } from '../models/RoleResponse';
import type { RolesResponse } from '../models/RolesResponse';
import type { WorkflowTemplateConfigRequest } from '../models/WorkflowTemplateConfigRequest';
import type { WorkflowTemplateConfigResponse } from '../models/WorkflowTemplateConfigResponse';
import type { WorkflowTemplateConfigUpdateRequest } from '../models/WorkflowTemplateConfigUpdateRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganisationsService {

    /**
     * @returns RolesResponse Organisation Roles
     * @throws ApiError
     */
    public static getMyRoles(): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_organisation_roles',
        });
    }

    /**
     * @returns OrganisationsResponse Your organisations
     * @throws ApiError
     */
    public static getMyOrganisations(): CancelablePromise<OrganisationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_organisations',
        });
    }

    /**
     * @returns LastSelectedOrganisationResponse Last selected organisations
     * @throws ApiError
     */
    public static getLastSelectedOrganisation(): CancelablePromise<LastSelectedOrganisationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/my_organisations/last_selected_organisation',
        });
    }

    /**
     * @param requestBody
     * @returns any Update last selected organisation
     * @throws ApiError
     */
    public static updateLastSelectedOrganisation(
        requestBody: LastSelectedOrganisationRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/my_organisations/last_selected_organisation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param userId
     * @returns any Organisation role removed, no content
     * @throws ApiError
     */
    public static removeMyOrganisationRole(
        orgId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/my_organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
        });
    }

    /**
     * @returns OrganisationsResponse Organisations
     * @throws ApiError
     */
    public static getAllOrganisations(): CancelablePromise<OrganisationsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations',
        });
    }

    /**
     * @param requestBody
     * @returns OrganisationResponse Organisation added
     * @throws ApiError
     */
    public static addOrganisation(
        requestBody: CreateOrganisationRequest,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param requestBody
     * @returns OrganisationResponse Add credits for organisation
     * @throws ApiError
     */
    public static addCredits(
        orgId: string,
        requestBody: CreditsRequest,
    ): CancelablePromise<OrganisationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations/{org_id}/credits',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @returns Project Organisation Projects
     * @throws ApiError
     */
    public static getAllMemberProjects(
        orgId: string,
    ): CancelablePromise<Array<Project>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations/{org_id}/projects',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId
     * @returns RolesResponse Organisation Roles
     * @throws ApiError
     */
    public static getAllMemberRoles(
        orgId: string,
    ): CancelablePromise<RolesResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations/{org_id}/roles',
            path: {
                'org_id': orgId,
            },
        });
    }

    /**
     * @param orgId
     * @param userId
     * @param requestBody
     * @returns RoleResponse Role Updated
     * @throws ApiError
     */
    public static setOrganisationRole(
        orgId: string,
        userId: string,
        requestBody: RoleRequest,
    ): CancelablePromise<RoleResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param userId
     * @returns any Organisation role removed, no content
     * @throws ApiError
     */
    public static removeOrganisationRole(
        orgId: string,
        userId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organisations/{org_id}/roles/{user_id}',
            path: {
                'org_id': orgId,
                'user_id': userId,
            },
        });
    }

    /**
     * @param orgId
     * @param projectId
     * @param workflowTemplateId
     * @returns WorkflowTemplateConfigResponse List workflow config
     * @throws ApiError
     */
    public static getWorkflowTemplateConfigs(
        orgId: string,
        projectId?: string | null,
        workflowTemplateId?: string | null,
    ): CancelablePromise<Array<WorkflowTemplateConfigResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/organisations/{org_id}/workflow_configs',
            path: {
                'org_id': orgId,
            },
            query: {
                'project_id': projectId,
                'workflow_template_id': workflowTemplateId,
            },
        });
    }

    /**
     * @param orgId
     * @param configId
     * @returns any Successfully updated the workflow config to inactive
     * @throws ApiError
     */
    public static deleteWorkflowTemplateConfig(
        orgId: string,
        configId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/organisations/{org_id}/workflow_template_config/{config_id}',
            path: {
                'org_id': orgId,
                'config_id': configId,
            },
        });
    }

    /**
     * @param orgId
     * @param configId
     * @param requestBody
     * @returns any Update workflow template config
     * @throws ApiError
     */
    public static updateWorkflowTemplateConfig(
        orgId: string,
        configId: string,
        requestBody: WorkflowTemplateConfigUpdateRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/organisations/{org_id}/workflow_template_config/{config_id}',
            path: {
                'org_id': orgId,
                'config_id': configId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param orgId
     * @param requestBody
     * @returns any Add workflow configuration
     * @throws ApiError
     */
    public static addWorkflowTemplateConfig(
        orgId: string,
        requestBody: WorkflowTemplateConfigRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/organisations/{org_id}/worlfow_template_config',
            path: {
                'org_id': orgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
