
import { Button, CounterBadge, Tag, TagGroup, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import * as React from 'react';
import { Link, useParams } from "react-router-dom";
import comingSoonIcon from "../../assets/icons/discover-apps/coming-soon.svg";
import workFlow_icon from "../../assets/icons/nav_icons/workflows.svg";
import TalkToExpertModal from '../discover_apps/TalkToExpertModal';
import Footer from '../landing/common/Footer';
import Demo from './Demo';
import { deafultWorkFlowData } from './common/DefaultWorkFlowData';
import HighlightText from './common/HighlightText';

interface DemoLink {
    name: string;
    path: string;
    type: string;
}
interface MoreInfos {
    title: string;
    info: string;
    hasOr?: boolean;
    hascsvFile?: boolean;
}
interface WorkFlowProps {
    id: string;
    icon: string;
    title: string;
    info: string;
    hasTryOut?: boolean;
    hasComingSoon?: boolean;
    tagNames: string[];
    overView: string;
    overView1?: string;
    workFlowStages: MoreInfos[];
    inputParameters: MoreInfos[];
    worflowOutputs: MoreInfos[];
    processingPricing: string;
    storagePricing: string;
    demoLink: DemoLink[];
}

const DefaultWorkFlow = () => {
    const classes = useStyles()
    const { workFlowId } = useParams();
    const [selectedWorkFlow, setSelectedWorkFlow] = React.useState<WorkFlowProps | null>(null);
    const [talkToExpertModal, setTalkToExpertModal] = React.useState<boolean>(false)

    React.useEffect(() => {
        const singleSelectedWorkFLow = deafultWorkFlowData.find((ele) => ele.id === workFlowId);
        if (singleSelectedWorkFLow) {
            setSelectedWorkFlow(singleSelectedWorkFLow)
        }
    }, [workFlowId])

    const toggleTalkToExpertDialog = (flag: boolean) => {
        setTalkToExpertModal(flag)
    }

    type TalkToExpertForm = {
        fullName: string;
        email: string;
        phoneNumber: string;
        customMessage: string;
    }
    const onSubmit = (formValues: TalkToExpertForm) => {
        console.log('formValues recived', formValues)
        setTalkToExpertModal(false)
    }

    return (
        <>
            <div className={classes.projectHeaderText}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Link style={{ textDecoration: "none", fontSize: '1rem', color: "black", fontWeight: "normal" }} className={classes.flexContent} to="/workFlow-library"><img alt="projectIcon" style={{ paddingRight: ".5em", }} src={workFlow_icon} /> Workflow Library</Link></div> <p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>ideaForge Plugins</div><p style={{ margin: "0 .2em " }}>{'>'}</p> <div style={{ fontSize: '1rem' }}>{selectedWorkFlow?.title}</div>
            </div>
            <div className={classes.bgContainer}
                style={{
                    background: tokens.colorNeutralBackground1,
                    overflow: "auto",

                }}
            >
                <div className={classes.subHeader} style={{
                }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <h2 style={{ color: "#043957", fontWeight: "700" }}>{selectedWorkFlow?.title}</h2>
                        <div style={{ marginLeft: "1em" }}>
                            {
                                selectedWorkFlow && <TagGroup role="list" className={classes.tagContainer} >
                                    {
                                        selectedWorkFlow.tagNames.map((item, index) => <Tag key={index} role="listitem" className={classes.tagBar} style={{ height: "3.5vh", borderRadius: "4px" }}>{item}</Tag>
                                        )
                                    }
                                </TagGroup>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: "2em", width: "75%", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ width: "70%" }}>
                            <div>{
                                selectedWorkFlow && (<>
                                    <h2 className={classes.heading}>About</h2>
                                    <p className={classes.info}>
                                        <HighlightText text={selectedWorkFlow?.overView} />
                                    </p>
                                    {
                                        selectedWorkFlow?.overView1 && (
                                            <p className={classes.info}>
                                                <HighlightText text={selectedWorkFlow?.overView1} />
                                            </p>
                                        )
                                    }
                                </>
                                )}
                            </div>
                            <div style={{ marginTop: "2em" }}>{
                                selectedWorkFlow && (<>
                                    <h2 className={classes.heading}>Workflow Stages</h2>
                                    {
                                        selectedWorkFlow.workFlowStages.map((ele, index) => (
                                            <div key={index} style={{ margin: "1em 0", display: "flex" }}>
                                                <CounterBadge style={{ padding: "1.1em", background: "#DFEDDB", color: "#000000", fontWeight: "500", fontSize: "1.2rem" }} size='extra-large' count={index + 1} shape="rounded" />
                                                <div style={{ margin: "0 0.5em", padding: 0 }}>
                                                    <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                    <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </>
                                )}
                            </div>
                            <div style={{ marginTop: "2em" }}>{
                                selectedWorkFlow && (<>
                                    <h2 className={classes.heading}>Data Requisites and Results</h2>
                                    <div style={{ margin: ".8em 0", display: "flex", alignItems: "baseline" }}>
                                        <div style={{ width: "30%" }}>
                                            <h2 className={classes.subHeading2}>Input Parameters</h2>
                                        </div>
                                        <div style={{ width: "70%" }}>
                                            {
                                                selectedWorkFlow.inputParameters.map((ele, index) => (
                                                    <>
                                                        <div key={index} style={{ padding: 0, marginBottom: "1em" }}>
                                                            <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                            <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                                            {ele.hascsvFile && (
                                                                <div style={{ marginLeft: "1em", display: "flex", alignItems: "baseline" }}>
                                                                    <p className={classes.subHeading2}>Inputs:</p>
                                                                    <div style={{ lineHeight: "1px", marginLeft: ".5em" }}>
                                                                        <p className={classes.info}>EPSG Code:</p>
                                                                        <p className={classes.info}>{`Resolution (meters or feet)`}</p>
                                                                    </div>
                                                                </div>)
                                                            }
                                                            {
                                                                ele.hasOr && index !== selectedWorkFlow.inputParameters.length - 1 && (<>
                                                                    <p className={classes.info} style={{ textAlign: "center", marginTop: "0.5em" }}>-or-</p>

                                                                </>
                                                                )
                                                            }
                                                        </div >
                                                    </>
                                                ))
                                            }

                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "end", margin: "0.5em 0", padding: 0 }}>
                                        <p style={{ border: "1px solid #D0DCEE", width: "70%" }}></p>
                                    </div>
                                    <div style={{ margin: ".8em 0", display: "flex", alignItems: "baseline" }}>
                                        <div style={{ width: "30%" }}>
                                            <h2 className={classes.subHeading2}>Workflow Outputs</h2>
                                        </div>
                                        <div style={{ width: "70%" }}>
                                            {
                                                selectedWorkFlow.worflowOutputs.map((ele, index) => (
                                                    <div key={index} style={{ padding: 0, marginBottom: "1em" }}>
                                                        <p className={classes.subHeading2} style={{ margin: 0, padding: 0 }}>{ele.title}</p>
                                                        <p className={classes.info} style={{ margin: 0, padding: ".2em 0", color: "#696969" }}>{ele.info}</p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </>
                                )}
                            </div>
                        </div>
                        <div style={{ width: "25%" }}>
                            {
                                selectedWorkFlow?.hasTryOut && (
                                    <div style={{ border: "1px solid #E3E9F2", background: "linear-gradient(144.88deg, #FBCB83 2.71%, #FFC3C3 92.61%)", borderRadius: "16px", padding: ".8em", textAlign: "center" }}>
                                        <h2 className={classes.heading}>Try Out Now!!</h2>
                                        <div style={{ margin: "1em 0" }}>
                                            <h2 className={classes.info}>Our experts will assist you in running a sample dataset and guide you through the process to enhance your experience.</h2>
                                        </div>
                                        <Button onClick={() => toggleTalkToExpertDialog(true)} style={{ width: "90%", color: "#FF553B", borderRadius: "6px", border: "0.75px solid #FFFFFF" }}>Talk To Expert</Button>
                                    </div>
                                )
                            }
                            <div style={{ border: "1px solid #E3E9F2", background: "#F2FFEE", margin: "1.5em 0", borderRadius: "16px", padding: ".8em", textAlign: "center" }}>
                                <h2 className={classes.heading}>Processing Price</h2>
                                <div style={{ margin: "1em 0" }}>{
                                    selectedWorkFlow?.hasComingSoon ?
                                        <img src={comingSoonIcon} /> : <>
                                            <h2 className={classes.heading} style={{ fontSize: "2rem" }}>{selectedWorkFlow?.processingPricing}</h2>
                                            <h2 className={classes.heading} style={{ paddingBottom: "0", fontSize: "1.5rem" }}>Credits*</h2>
                                            <p className={classes.subInfo} style={{ marginTop: ".5em" }}>per Square Kilometer</p>
                                        </>
                                }
                                </div>
                                {
                                    !selectedWorkFlow?.hasComingSoon && (
                                        <p className={classes.subInfo}>*20 GP (GigaPixels) is allocated for 1 sq. km.
                                            using a basic grid flight plan</p>
                                    )
                                }

                            </div>
                            <div style={{ border: "1px solid #E3E9F2", margin: "1.5em 0", background: "#FDFFEE", borderRadius: "16px", padding: ".8em", textAlign: "center" }}>
                                <h2 className={classes.heading}>Storage Price</h2>
                                <div style={{ margin: "1em 0" }}>
                                    <h2 className={classes.heading} style={{ fontSize: "2rem" }}>{selectedWorkFlow?.storagePricing}</h2>
                                    <h2 className={classes.heading} style={{ paddingBottom: "0", fontSize: "1.5rem" }}>Credits<span style={{ fontWeight: "400", fontSize: "1rem" }}>/100 GB</span></h2>
                                    <p className={classes.subInfo} style={{ marginTop: ".5em" }}>per month</p>
                                </div>
                            </div>

                            <div style={{ border: "1px solid #E3E9F2", margin: "1.5em 0", background: "#FBFBFB", borderRadius: "16px", padding: ".8em" }}>
                                <h2 className={classes.heading} style={{ textAlign: "center" }}>Demo</h2>
                                <div style={{ margin: "1em 0" }}>
                                    {
                                        selectedWorkFlow && !selectedWorkFlow.hasComingSoon ? (
                                            <Demo demoLinks={selectedWorkFlow.demoLink} />
                                        ) : <div style={{ textAlign: "center" }}>
                                            <img src={comingSoonIcon} />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "2em" }}>
                    <Footer />
                </div>
                <TalkToExpertModal visible={talkToExpertModal} onSubmit={(d: any) => {
                    onSubmit(d)
                }} toggleModal={toggleTalkToExpertDialog} />
            </div >
        </>
    )
}

const useStyles = makeStyles({
    projectHeaderText: {
        height: "3rem",
        paddingLeft: "1.25rem",
        display: "flex",
        alignItems: "center",
        color: "#2B2B2B",
        ...shorthands.borderBottom("1px", "solid", "#E3E9F2"),
        "> span": {
            fontWeight: "700",
        },
    },
    bgContainer: {
        width: "100%",
        ...shorthands.padding("1.5em"),
        height: "calc(91vh - 3rem)",
        scrollbarWidth: "thin",
    },
    flexContent: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    subHeader: {
        fontWeight: "600",
        fontSize: "1.5em",
    },
    cardHeading: {
        fontSize: "1.3rem",
        color: "#000000",
        fontWeight: 500,
        lineHeight: "1.5rem",
        ...shorthands.margin(0),
    },
    tagBar: {
        backgroundColor: "#E3E6EB",
        color: "#272727",
        fontSize: ".5rem !important",
        ...shorthands.padding("4px", "10px"),
        display: "flex", justifyContent: "center", alignItems: "center",
        '& .f1g03r3y': {
            fontSize: '.7rem',
        },
    },
    subHeading2: {
        fontSize: "1rem",
        color: "#000000",
        lineHeight: "1rem",
        fontWeight: "500",
        marginTop: "1em",
        marginBottom: "1em"
    },
    heading: {
        fontSize: "1.2rem",
        color: "#272727",
        fontWeight: 600,
        paddingBottom: ".3em",
        ...shorthands.margin(0),
    },
    info: {
        fontSize: ".9rem",
        color: "#000000",
        fontWeight: 400,
        paddingBottom: ".5em",
        marginTop: ".8em"
    },
    subInfo: {
        fontSize: ".75rem",
        color: "#000000",
        fontWeight: 400,
        lineHeight: "15px",
        margin: "0",
        padding: "0"
    },

    tagContainer: {
        display: "flex",
        flexWrap: "wrap",
        ...shorthands.gap(".2em")
    }


}
)

export default DefaultWorkFlow