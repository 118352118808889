import createMaps_icon from "../../../assets/icons/workflow_images/CreateMapsWithODM.png";
import createMapswithAgisoftMetashape_icon from "../../../assets/icons/workflow_images/createMapsWithAGISOFT.png";
import createMapsWithAgisoftMetashapeUsingGCPs_icon from "../../../assets/icons/workflow_images/createMapsWithAGISOFTUsingGCPs.png";
import createStockpileReport_icon from "../../../assets/icons/workflow_images/stockpileReport.png";
import highResolutionDEMRorBFT_icon from "../../../assets/icons/workflow_images/highResolutionDemForBFT.png"
import boundaryFile from "../../../assets/icons/workflow_images/boundaryFile.png"
import pointCloud from "../../../assets/icons/workflow_images/pointCloud.png"
import dtm from "../../../assets/icons/workflow_images/digitalTerrainModel.png"
import planimmetric from "../../../assets/icons/workflow_images/planimetricMap.png"
import changeDetetection from "../../../assets/icons/workflow_images/changeDetection.png"
import contourMaps from "../../../assets/icons/workflow_images/contourMaps.png"
import agisoft from "../../../assets/icons/workflow_images/agisoft.jpg"
import gcp from "../../../assets/icons/workflow_images/gcp.jpg"

export const deafultWorkFlowData = [
    {
        id: "1",
        icon: createMaps_icon,
        title: 'Create maps with ODM',
        info: "Perform photogrammetry and generate maps with OpenDroneMap.",
        tagNames: ["Photogrammetry", "OpenDroneMap"],
        overView: "Use this workflow to create aerial and elevation maps from raw images. Useful for vegetation areas and not recommended for large areas and the areas with a lot of structures. This workflow uses OpenDroneMap photogrammetry software to process the images. This workflow processing is not for CROSS GRID, only for SIMPLE GRID flight plan.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with OpenDroneMap",
                info: "Creating geospatial maps and 3D models from aerial imagery collected by drones, using the OpenDroneMap(ODM)"
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces "
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
        ],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
        ],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
        ],
        processingPricing: "50",
        storagePricing: "12",
        demoLink: [
            { name: "Demo video - Create Map With ODM", type: "video", path: 'https://www.youtube.com/watch?v=TGCBdo0dGNI' },
            { type: "docs", name: "Photogrammetry_report", path: "/assets/workflow_docs/createMapWithODM-photogrammetry_report.pdf" },
        ],

    },
    {
        id: "2",
        icon: createMapswithAgisoftMetashape_icon,
        title: 'Create maps with Agisoft Metashape',
        info: "Perform photogrammetry and generate maps with Agisoft Metashape.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with Agisoft Metashape",
                info: "Create detailed 2D/3D maps and models from aerial images using Metashape."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
            {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            },],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz or .las format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
        ],
        tagNames: ["Photogrammetry", "Agisoft", "Metashape"],
        overView: "Utilize this workflow to create aerial and elevation maps from raw images using Agisoft Metashape photogrammetry software. Ideal for various use cases and large-scale mapping projects. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo Video - Create Map With Agisoft", type: "video", path: 'https://www.youtube.com/watch?v=a_Q5PLd7bYg' },
            { type: "docs", name: "Agisoft Metashape report", path: "/assets/workflow_docs/agisoftMetashapeReport.pdf" },
        ],
    }, {
        id: "3",
        icon: createMapsWithAgisoftMetashapeUsingGCPs_icon,
        title: 'Create maps with Agisoft Metashape using GCPs',
        info: "Perform photogrammetry and generate maps with Agisoft Metashape with Ground Control Points.",
        processingPricing: "80",
        storagePricing: "12",
        inputParameters:
            [
                {
                    title: "Geo-Tagged images (.JPG format)",
                    info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
                },
                {
                    title: "Ground control point( .csv format)",
                    info: "Readings(Northing, Easting, Elevation or Latitude, longitude, elevation) in CSV format"
                },

            ],
        tagNames: ["Agisoft", "Metashape", "GCPs"],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz or .las format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
        ],
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Tag GCPs(Ground Control Points) in the images",
                info: "Label and identify specific points in aerial images that correspond to known ground locations, enabling georeferencing and accurate mapping"
            },
            {
                title: "Generate maps with Agisoft Metashape with GCPs",
                info: "Create highly accurate 2D/3D maps and models from aerial images using Metashape's photogrammetry software, guided by tagged Ground Control Points (GCPs) for precise georeferencing."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            }, {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            },],
        overView: "If your project requires ground control points along with PPK, use this workflow to create aerial and elevation maps. This workflow utilizes Agisoft Metashape photogrammetry software to process the images. It is highly useful for various use cases and large-scale mapping. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo Video - Create maps with Agisoft Metashape using GCPs", type: "video", path: 'https://www.youtube.com/watch?v=8xA_yQL5FjM' },
            { type: "docs", name: "Agisoft Metashape Report", path: "/assets/workflow_docs/agisoftMetashapeReport.pdf" },
        ],
    }, {
        id: "4",
        icon: createStockpileReport_icon,
        title: 'Create stockpile report',
        info: "Produce map data with OpenDroneMap and generate stockpile report.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with OpenDroneMap",
                info: "Creating geospatial maps and 3D models from aerial imagery collected by drones, using the OpenDroneMap(ODM)"
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces "
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
            {
                title: "Upload the stockpile boundaries",
                info: "Upload in geojson format"
            },
            {
                title: "Generate stockpile report",
                info: "Detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            }
        ],
        processingPricing: "100",
        storagePricing: "12",
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
            {
                title: "Stockpile boundaries ( .geojson format )",
                info: "File in .geojson format and should be in geographic coordinate system."
            },
        ],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "Stockpile report",
                info: "PDF Report containing detailed information about a stockpile, including its volume, weight, material composition, and spatial dimensions"
            },
        ],
        tagNames: ["Stockpile", "OpenDroneMap"],
        overView: "Use this workflow to create a stockpile report from raw images. It is very useful for monitoring projects like mining, earthworks, etc. This workflow requires a one-time setup of stockpile boundaries and no further human intervention. It runs the photogrammetry session on uploaded images using OpenDroneMap and creates an orthomosaic and DEM. Based on these outputs, it generates a stockpile report. This workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
            { name: "Demo video - Create stockpile report", type: "video", path: 'https://www.youtube.com/watch?v=DKualn7Kr78' },
            { type: "docs", name: "Stockpile Report", path: "/assets/workflow_docs/stockpileReport.pdf" },
        ],

    }, {
        id: "5",
        icon: highResolutionDEMRorBFT_icon,
        title: 'High resolution DEM for BFT',
        info: "Generate a high-resolution DEM and seamlessly convert it to BFT's compatible format.",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate Digital Elevation Model with Agisoft Metashape",
                info: "Creating a highly accurate 3D model of the terrain using Metashape's, converting overlapping aerial images into a detailed Digital Elevation Model (DEM)."
            },
            {
                title: "Convert DEM to BFT's compatible format",
                info: "Digital Elevation Model (DEM) is made ready for BFT compatible format"
            }],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            }
        ],
        tagNames: ["BFT", "Digital Elevation Model", "High resolution"],
        worflowOutputs: [
            {
                title: "High resolution DEM (. geotiff format )",
                info: "A highly detailed Digital Elevation Model (DEM) in GeoTIFF format, providing precise elevation data and topographic information at a high spatial resolution, ideal for applications requiring accurate terrain representation. The output will also contain 3D point cloud Orthomosiac in (.tif format) and BFT Compatible high resolution DEM (.tif format)."
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "Use this workflow to create a BFT-compatible digital elevation model from uploaded raw images. This workflow utilizes Agisoft Metashape photogrammetry software to process the raw data and generate the digital elevation model. Note: This workflow processing is only for simple grid flight plans, not for cross grid.",
        demoLink: [
            { name: "Demo video - High resolution DEM for BFT", type: "video", path: 'https://www.youtube.com/watch?v=-kA7Xz8qIDU' },
            { type: "docs", name: "Report-High Resolution DEM For BFT", path: "/assets/workflow_docs/report-HighResolutionDEMForBFT.pdf" },
        ],
    },
    {
        id: "6",
        icon: boundaryFile,
        title: 'USGS LiDAR to DEM workflow (Boundary file - geojson file to DEM conversion)',
        info: "Generate high resolution DEM from a USGS lidar point cloud and convert them to BFT compatible digital elevation model",
        hasComingSoon: true,
        workFlowStages: [
            {
                title: "Upload or select area of interest ",
                info: "Upload or define the geographic area where the workflow will be applied."
            },
            {
                title: "Create digital elevation model for the selected area of interest",
                info: "Generate a detailed digital elevation model (DEM) for the specified area, providing elevation data for analysis."
            },
            {
                title: "Prepare digital elevation model to display",
                info: "Process and format the digital elevation model (DEM) for visualization and interpretation."
            },
            {
                title: "Prepare point cloud to display",
                info: "Process and refine the point cloud data for optimal visualization and analysis"
            },
            {
                title: "Create BFT compatible digital elevation model",
                info: "Generate a digital elevation model that meets BFT (Blue Fire Touch) compatibility standards."
            },
        ],
        inputParameters: [
            {
                title: "Boundary file in GeoJson",
                info: "Boundary file in GeoJSON format, set in a geographic coordinate system."
            },
        ],
        tagNames: ["USGS LiDAR", "DEM", "USGS"],
        worflowOutputs: [
            {
                title: "Point cloud in .laz format",
                info: "Compressed USGS LiDAR point cloud in .laz format for efficient storage and processing."
            }, {
                title: "DEM in .tif format",
                info: "A TIFF file containing a Digital Elevation Model, which represents the terrain's elevation data in a raster format for detailed topographic analysis"
            }, {
                title: "Blue Fire Touch (BFT) compatible dem in .tif format",
                info: "DEM in .tif format, optimized for compatibility with Blue Fire Touch (BFT) software."
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "The USGS LiDAR to DEM Workflow enables the conversion of USGS LiDAR point cloud data into a high-resolution Digital Elevation Model (DEM). This workflow supports the generation of DEMs from boundary files in GeoJSON format, ensuring precise terrain representation. The resulting DEM can also be converted into a Blue Fire Touch (BFT) compatible format for seamless integration with specialized applications.",
        demoLink: [
        ],
    },
    {
        id: "7",
        icon: pointCloud,
        title: 'LiDAR to DEM workflow (Point Cloud - laz file to DEM conversion)',
        info: "Generate high resolution DEM from a lidar point cloud and convert them to BFT compatible digital elevation model",
        hasComingSoon: true,
        workFlowStages: [
            {
                title: "Upload the LiDAR point cloud",
                info: "Upload the LiDAR point cloud to process and analyze 3D spatial data."
            },
            {
                title: "Create digital elevation model",
                info: "Generate a digital elevation model (DEM) to visualize terrain and topography."
            }, {
                title: "Prepare digital elevation model to display",
                info: "Format and optimize the digital elevation model (DEM) for clear and effective visualization."
            },
            {
                title: "Prepare point cloud to display",
                info: "Format and optimize the point cloud data for accurate and clear visualization."
            }, {
                title: "Create BFT compatible digital elevation model",
                info: "Generate a digital elevation model that meets BFT compatibility standards for enhanced integration."
            },
        ],
        inputParameters: [
            {
                title: "LiDAR point cloud",
                info: "LiDAR point cloud data capturing detailed 3D spatial information of the terrain and features."
            },
        ],
        tagNames: ["LiDAR", "DEM"],
        worflowOutputs: [
            {
                title: "Point cloud in .laz format",
                info: "Compressed LiDAR point cloud in .laz format for efficient storage and processing."
            },
            {
                title: "DEM in .tif format",
                info: "A TIFF file containing a Digital Elevation Model, which represents the terrain's elevation data in a raster format for detailed topographic analysis"
            }, {
                title: "Blue Fire Touch (BFT) compatible dem in .tif format",
                info: "DEM in .tif format, optimized for compatibility with Blue Fire Touch (BFT) software."
            },
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "The LiDAR to DEM Workflow enables the generation of high-resolution Digital Elevation Models (DEMs) from LiDAR point cloud data. By converting point clouds in .laz format into DEMs, this workflow ensures compatibility with Blue Fire Touch (BFT) systems. Use this process to create precise and detailed elevation models tailored for advanced applications, including those requiring BFT-compatible DEMs.",
        demoLink: [
        ],
    },
    {
        id: "8",
        icon: dtm,
        title: 'Digital Terrain Model (DTM)',
        hasTryOut: true,
        info: "Use this workflow to create DTM from surface model or point cloud or CSV file",
        hasComingSoon: true,
        workFlowStages: [
            {
                title: "Upload the workflow file",
                info: "Upload the necessary input data or project file into the system to start the workflow process."
            },
            {
                title: "Generate Digital Terrain Model",
                info: "Create a precise representation of the bare ground surface by processing elevation data."
            }, {
                title: "Generate tiles for a map view from Digital Terrain Model",
                info: "Create map tiles from the Digital Terrain Model for seamless visualization and analysis."
            },
        ],
        inputParameters: [
            {
                title: "Mosaic/Digital Elevation Model",
                info: "Upload mosaic and digital elevation model",
                hasOr: true
            },
            {
                title: "Point Cloud",
                info: "Collection of data points representing a 3D space, provided in .laz format",
                hasOr: true
            },
            {
                title: "CSV file with x,y,z information",
                info: "CSV file containing x, y, and z coordinates for spatial data points.",
                hasOr: true,
                hascsvFile: true
            },
        ],
        tagNames: ["DTM", "Digital Elevation Model"],
        worflowOutputs: [
            {
                title: "DTM in .tif format",
                info: "Offers a detailed digital terrain model representing the elevation of the land surface."
            }
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "This workflow generates a Digital Terrain Model (DTM) by processing data from Digital Elevation Models, Point Clouds, or CSV files. DTMs depict the bare ground surface, excluding objects like buildings and vegetation, and are essential for flood risk assessment, land-use planning, and other applications.",
        overView1: "The process involves uploading relevant data, generating the DTM, and producing detailed, colorized maps in .TIF format. This detailed terrain information aids in various fields, including geography, engineering, and environmental studies, by providing clear insights into the terrain's topography and geomorphological features.",
        demoLink: [
        ],
    },
    {
        id: "9",
        icon: contourMaps,
        title: 'Contour Maps',
        hasComingSoon: true,
        info: "Use this workflow to create Contour map with required interval from either a DEM or DTM",
        workFlowStages: [
            {
                title: "Upload the workflow file",
                info: "Upload the workflow file to initiate the process and set up the desired workflow steps."
            },
            {
                title: "Generate contour maps",
                info: "Generate contour maps to visualise terrain elevations and slopes using contour lines."
            },
            {
                title: "Generate tiles for a map view from vector",
                info: "Create map tiles from vector data for efficient and scalable map rendering."
            }
        ],
        inputParameters: [
            {
                title: "Digital Elevation Model or Digital Terrain Model",
                info: "Resolution in meters. Default is 0.5 meters."
            },
            {
                title: "Contour intervals (in meters or feet)",
                info: "Vertical spacing between contour lines on a map, indicating changes in elevation."
            }
        ],
        tagNames: ["LiDAR", "DEM", "USGS"],
        worflowOutputs: [
            {
                title: "Contour map in .dxf format & .geojson format",
                info: "Representing elevation changes with contour lines for detailed topographic analysis."
            }
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "This workflow generates contour maps, which are two-dimensional representations of the Earth's surface showing elevation and terrain shape using contour lines. These maps are essential for planning routes, designing infrastructure, and understanding geological features.",
        overView1: "To create a contour map, the workflow involves uploading a Digital Elevation Model (DEM) or Digital Terrain Model (DTM) and specifying the contour interval in meters or feet. The output includes a contour map in .DXF or .GeoJson format, providing detailed insights into the terrain's topography. This information is valuable for various applications, including road design, construction, and geological studies, enabling better planning and decision-making.",
        demoLink: [
        ],
    },
    {
        id: "10",
        icon: planimmetric,
        title: 'Planimetric Map',
        hasComingSoon: true,
        info: "Use this workflow to create planimetric map from Orthomosaic",
        workFlowStages: [
            {
                title: "Upload the workflow file",
                info: "Upload the workflow file to initiate the process and set up the desired workflow steps."
            },
            {
                title: "Generate Topographic Map",
                info: "Create a topographic map to visualize terrain elevation and landforms."
            },
            {
                title: "Generate tiles for a map view from vector",
                info: "Generate map tiles from vector data for seamless and zoomable map visualisation."
            }
        ],
        inputParameters: [
            {
                title: "Orthomosaic in .tif format",
                info: "Offering a high-resolution, georeferenced image that accurately represents the surface area captured by UAV."
            }

        ],
        tagNames: ["Planimetric", "Topographic"],
        worflowOutputs: [
            {
                title: "Planimetric Map in .dxf format & .pdf format",
                info: "A DXF file containing a detailed planimetric map, featuring vector-based representations of geographic features. A PDF file containing a planimetric map, providing a high-quality, printable visual representation of geographic features."
            }
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "This workflow creates a planimetric map, which provides a detailed and accurate representation of natural and man-made features on the Earth's surface, using symbols and colors. Planimetric maps are essential in fields like geography, geology, engineering, and land-use planning, offering a clear depiction of surface features without elevation data.",
        overView1: "To generate a planimetric map, the workflow involves uploading an Orthomosaic.tif file. The process then produces the map in .PDF or .DXF format, allowing for a comprehensive and precise view of the area's layout. This information is crucial for tasks such as urban planning, infrastructure development, and environmental analysis, providing valuable insights into the spatial arrangement and characteristics of surface features.",
        demoLink: [
        ],
    },
    {
        id: "11",
        icon: changeDetetection,
        title: 'Generate Change Detection Report',
        hasComingSoon: true,
        info: "Use this workflow to perform change detection analysis by taking two mosaics and corresponding DEMs as inputs. It generates a comprehensive report for a specified project site, comparing the elevation data between two time periods. The report not only details the elevation changes but also visually highlights areas where significant changes have occurred. This workflow is especially useful for tracking landscape alterations, construction progress, or excavation progress over time..",
        workFlowStages: [
            {
                title: "Upload day 1 Orthomosaic",
                info: "Day 1 Orthomosaic"
            },
            {
                title: "Upload day 1 digital elevation model",
                info: "Day 1 Digital Elevation Model"
            },
            {
                title: "Upload day 2 Orthomosaic",
                info: "Day 2 Orthomosaic"
            }, {
                title: "Upload day 2 digital elevation model",
                info: "Day 2 Digital Elevation Model"
            },
            {
                title: "Generate Change Detection Report",
                info: "Generate Change Detection Report"
            },
            {
                title: "Preparing for orthomosaic display - day 1",
                info: "Preparing for orthomosaic display - day 1"
            },
            {
                title: "Preparing for orthomosaic display - day 2",
                info: "Preparing for orthomosaic display - day 2"
            },
            {
                title: "Preparing for visual changes vector data display",
                info: "Visual changes vector display"
            }
        ],
        inputParameters: [
            {
                title: "Day 1 orthomosac in .tif format",
                info: "Day 1 orthomosac in .tif format"
            },
            {
                title: "Day 1 DEM   in .tif format",
                info: "Day 1 DEM   in .tif format"
            },
            {
                title: "Day 2 orthomosaic   in .tif format",
                info: "Day 2 orthomosaic   in .tif format"
            },
            {
                title: "Day 2 DEM  in .tif format",
                info: "Day 2 DEM  in .tif format"
            }
        ],
        tagNames: ['Compare', "Change Detection", "Evaluate", "Differentiate", "Correlate"],
        worflowOutputs: [
            {
                title: "Change Detection Report in PDF format",
                info: "Change Detection Report in PDF format"
            }
        ],
        processingPricing: "60",
        storagePricing: "12",
        overView: "The Change Detection Workflow facilitates the identification and quantification of changes in a specific area over time. By comparing spatial data sets from different time periods, this workflow allows users to detect and analyze alterations in landscapes, built environments, or other features of interest, providing critical insights into the dynamics of the area under study.",
        demoLink: [
        ],
    },
    {
        id: "12",
        icon: agisoft,
        title: 'Create maps and 3D model with Agisoft Metashape',
        hasComingSoon: true,
        info: "Perform photogrammetry and generate 3D outputs including other maps with Agisoft Metashape",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Generate maps with Agisoft Metashape",
                info: "Create detailed 2D/3D maps and models from aerial images using Metashape."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            },
            {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            },],
        inputParameters: [
            {
                title: "Geo-Tagged images (.JPG format)",
                info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
            },
        ],
        tagNames: ["3D Mesh", "Photogrammetry", "Metashape"],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz or .las format)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz format"
            },
            {
                title: "3D Mesh model (.mtl, .obj, .jpg)",
                info: "A 3D mesh model is a digital representation of an object's shape made up of interconnected polygons (usually triangles or quadrilaterals)."
            },
        ],
        processingPricing: "--",
        storagePricing: "12",
        overView: "Utilize this workflow to create aerial and elevation maps and 3D mesh model from raw images using Agisoft Metashape photogrammetry software. Ideal for various use cases and large-scale mapping projects. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
        ],
    },
    {
        id: "13",
        icon: gcp,
        title: 'Create maps and 3D model with Agisoft Metashape using Ground Control Points',
        hasComingSoon: true,
        info: "Perform photogrammetry and generate 3D outputs including other maps with Agisoft Metashape using GCPs",
        workFlowStages: [
            {
                title: "Prepare dataset",
                info: "Organising and optimising geo-tagged image data before generating orthomosaic maps"
            },
            {
                title: "Tag GCPs(Ground Control Points) in the images",
                info: "Label and identify specific points in aerial images that correspond to known ground locations, enabling georeferencing and accurate mapping"
            },
            {
                title: "Generate maps with Agisoft Metashape with GCPs",
                info: "Create highly accurate 2D/3D maps and models from aerial images using Metashape's photogrammetry software, guided by tagged Ground Control Points (GCPs) for precise georeferencing."
            },
            {
                title: "Generate tiles for a map view from an Orthomosaic",
                info: "Process of dividing a high-resolution Orthomosaic image into smaller, manageable pieces"
            },
            {
                title: "Generate tiles for a map view from Digital Elevation Model",
                info: "Process of converting a DEM into a visualisation-friendly format, dividing it into smaller tiles"
            }, {
                title: "Generate tiles for a 3D view",
                info: "Convert 3D models or data into smaller, manageable pieces (tiles) for efficient display and navigation of interactive 3D visualizations"
            }],
        inputParameters:
            [
                {
                    title: "Geo-Tagged images (.JPG format)",
                    info: "Photographs that contain geographical location metadata, typically in the form of latitude and longitude coordinates"
                },
                {
                    title: "Ground control point( .csv format)",
                    info: "Readings(Northing, Easting, Elevation or Latitude, longitude, elevation) in CSV format"
                },

            ],
        tagNames: ["3D Mesh", "Photogrammetry", "Metashape"],
        worflowOutputs: [
            {
                title: "Orthomosaic (.tif format)",
                info: "High-resolution, georeferenced map created from drone images, provided in .geotiff format"
            },
            {
                title: "Digital Elevation Model (DEM in .tif format)",
                info: "Georeferenced raster file that represents the topographic relief of an area, encoding elevation values On a two-dimensional grid"
            },
            {
                title: "3D point cloud (.laz , .las , .mtl, .obj format, and .jpg files)",
                info: "A 3D point cloud is a collection of data points representing a 3D space, provided in .laz,.las, .mtl, .obj format, and .jpg files"
            },
            {
                title: "3D Mesh model (.mtl, .obj, .jpg)",
                info: "A 3D mesh model is a digital representation of an object's shape made up of interconnected polygons (usually triangles or quadrilaterals)."
            },
        ],
        processingPricing: "--",
        storagePricing: "12",
        overView: "If your project requires ground control points along with PPK, use this workflow to create aerial, elevation maps, and 3D mesh model. This workflow utilizes Agisoft Metashape photogrammetry software to process the images. It is highly useful for various use cases and large-scale mapping. Please note, this workflow is designed for simple grid flight plans only, not cross grid.",
        demoLink: [
        ],
    },
]


