import { createContext } from 'react';
import { LastSelectedOrganisationResponse, UserResponse } from './services/openapi';
interface contextType {
    me: UserResponse | null,
    setMe: React.Dispatch<React.SetStateAction<UserResponse | null>>,
    isNewProjectOpen: boolean,
    setIsNewProjectOpen: React.Dispatch<React.SetStateAction<boolean>>,
    orgId: LastSelectedOrganisationResponse | null,
    setOrgId: React.Dispatch<React.SetStateAction<LastSelectedOrganisationResponse | null>>,
    isAddedNewOrganisation: boolean,
    setIsAddedNewOrganisation: React.Dispatch<React.SetStateAction<boolean>>,
    isOrganisationListUpdated: boolean,
    setIsOrganisationListUpdated: React.Dispatch<React.SetStateAction<boolean>>,
}
export const AuthContext = createContext<contextType>({
    me: null,
    setMe: () => { },
    isNewProjectOpen: false,
    setIsNewProjectOpen: () => { },
    orgId: null,
    setOrgId: () => { },
    isAddedNewOrganisation: false,
    setIsAddedNewOrganisation: () => { },
    isOrganisationListUpdated: false,
    setIsOrganisationListUpdated: () => { },
});

